export const Z_INDEX_BG_KEY = 'BG_KEY';
export const Z_INDEX_ASSET_BG_KEY = 'ASSET_BACKGROUND';
export const Z_INDEX_HERO_IMAGE_KEY = 'HERO_IMAGE';
export const Z_INDEX_CONTENT_LAYER = 'CONTENT_LAYER';
export const Z_INDEX_BORDER_LAYER = 'BORDER_LAYER';
export const Z_INDEX_ASSET_OVERLAY = 'ASSET_OVERLAY';
export const Z_INDEX_TEXT_LAYER_KEY = 'TEXT_LAYER';
export const Z_INDEX_ASSET_TEXT_OVERLAY_KEY = 'ASSET_TEXT_OVERLAY';
export const Z_INDEX_NAV_KEY = 'NAV';
export const Z_INDEX_NAV_OVERLAY_KEY = 'NAV_OVERLAY';
export const Z_INDEX_FULL_SCREEN_NAV_MENU_KEY = 'Z_INDEX_FULL_SCREEN_NAV_MENU_KEY';
export const Z_INDEX_ADMIN_BANNER_KEY = 'Z_INDEX_ADMIN_BANNER';

/**
 * Add z-index layer here; in decreasing order
 * ie. copy will have a higher z-index than bg
 */
const ZIndexLayers = [
  Z_INDEX_BG_KEY, // 0
  Z_INDEX_ASSET_BG_KEY, // 50
  Z_INDEX_HERO_IMAGE_KEY, // 100
  Z_INDEX_CONTENT_LAYER, // 150 (content container)
  Z_INDEX_BORDER_LAYER, // 200
  Z_INDEX_ASSET_OVERLAY, // 250
  Z_INDEX_TEXT_LAYER_KEY, // 300
  Z_INDEX_ASSET_TEXT_OVERLAY_KEY, // 350
  Z_INDEX_NAV_KEY, // 400
  Z_INDEX_NAV_OVERLAY_KEY, // 450
  Z_INDEX_FULL_SCREEN_NAV_MENU_KEY, // 500
  Z_INDEX_ADMIN_BANNER_KEY, // 600
];

const makeZIndices = (layers: string[]): Record<string, number> => {
  return layers.reduce((acc: Record<string, number>, layer: string, index: number) => {
    acc[layer] = index * 50;
    return acc;
  }, {});
};

export const Z_INDEX_MAP = makeZIndices(ZIndexLayers);
