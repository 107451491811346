import React, { useState } from 'react';
import { css } from '@emotion/react';
import COLORS from '@zola/zola-ui/src/styles/emotion/colors';
import { isDarkColor } from 'pages/publicStyleUtils/utils.styles';
import { FullComponentFontView } from './types';
import { mapTextTransformValue } from '.';

export interface LinkProps
  extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
    React.AriaAttributes {}

export const mapLinkStylesToComponent = (f: FullComponentFontView, backgroundColor?: string) => {
  const LinkComponent: React.FC<LinkProps> = ({ children, style, ...rest }) => {
    const [hover, setHover] = useState(false);

    const linkEmotionStyles = css`
      display: inline-block;
      font-size: inherit;
      line-height: inherit;
      font-weight: 600;
      text-decoration: underline;
      transition: background-color 300ms;

      &:hover {
        text-decoration: none;
      }
    `;
    const isDarkMode = backgroundColor ? isDarkColor(backgroundColor) : false;

    const inlineStyles = {
      fontFamily: f.fontFamily,
      textTransform: mapTextTransformValue(f.textTransform),
      color: hover
        ? `${isDarkMode ? COLORS.BLACK : `#${backgroundColor || COLORS.BLACK}`}`
        : `#${f.color}`,
      backgroundColor: hover ? `#${f.color}` : 'transparent',
    };

    return (
      <a
        {...rest}
        style={{ ...style, ...inlineStyles }}
        css={linkEmotionStyles}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {children}
      </a>
    );
  };
  return LinkComponent;
};
