import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import type { WPublicWeddingView } from '@zola/svc-web-api-ts-client';

type PublicV2MetadataProps = {
  wedding: WPublicWeddingView;
  metaImageUrl?: string;
};

const PublicV2Metadata = ({ wedding, metaImageUrl }: PublicV2MetadataProps): JSX.Element => {
  const router = useRouter();
  const { preview_type } = router?.query || {};
  const viewportContent =
    (preview_type as string | undefined)?.toUpperCase() === 'DESKTOP'
      ? 'width=1200'
      : 'width=device-width, initial-scale=1.0';

  const pageTitle = `${wedding.owner_first_name} ${wedding.owner_last_name}${
    wedding.partner_first_name
      ? ` and ${wedding.partner_first_name} ${wedding.partner_last_name}`
      : ''
  }'s Baby Registry - Zola Baby`;
  const pageDescription = `The baby registry of ${wedding.owner_first_name} ${
    wedding.owner_last_name
  }${
    wedding.partner_first_name && ` and ${wedding.partner_first_name} ${wedding.partner_last_name}`
  }`;
  const fallbackImg =
    'https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/logos/ZOLABABY_ICON-L_BLUE.png';
  const metaImageUrlFinal = metaImageUrl || fallbackImg;

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />

      <meta name="viewport" content={viewportContent} />
      {!wedding.enable_search_engine && <meta name="robots" content="noindex" />}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:image" content={metaImageUrlFinal} />
      <meta name="twitter:site" content="@zola" />
      <meta name="twitter:card" content="summary_large_image" />
    </Head>
  );
};

export default PublicV2Metadata;
