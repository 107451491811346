import styled from '@emotion/styled';
import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQuery';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 24px;
  align-items: center;
  justify-content: center;
  ${MEDIA_QUERY.EXCLUDE_MOBILE} {
    margin-bottom: 24px;
  }
`;

export const Title = styled.h1`
  margin-bottom: 24px;
  word-break: break-word;
`;

export const ArrivalDate = styled.h2`
  margin-bottom: 24px;
`;

export const Greeting = styled.p`
  margin-bottom: 24px;
  word-break: break-word;
`;

export const HeroImage = styled.img`
  ${MEDIA_QUERY.MOBILE} {
    margin-bottom: 24px;
  }
  max-width: 736px;
  border-radius: 16px;
  width: 100%;
`;

export const HeaderSection = styled.div`
  flex: 1 1 0;
  ${MEDIA_QUERY.MOBILE} {
    flex: 0 0 100%;
  }
  text-align: center;
`;
