import { css, SerializedStyles, Theme } from '@emotion/react';
import styled, { StyledComponent } from '@emotion/styled';
import type { CSSProperties } from 'react';
import type {
  WThemeComponentFontView,
  WThemeComponentBackgroundView,
  WAbsoluteAssetSizeView,
  WLengthView,
  WResponsiveAssetSizeViewWAbsoluteAssetSizeView,
  WInFlowThemeComponentAssetView,
  WInFlowAssetSizeView,
  WInFlowThemeComponentAssetSummaryView,
  WThemeComponentBackgroundImageView,
} from '@zola/svc-web-api-ts-client';
import { MEDIA_QUERY } from '@zola/zola-ui/src/styles/emotion';
import SPACING from '@zola/zola-ui/src/styles/emotion/spacing';
import _pick from 'lodash/pick';
import { isDarkColor } from 'pages/publicStyleUtils/utils.styles';
import { hexToRgb } from '@zola/zola-ui/src//util/color/hexToRgb';
import type { CroppedAbsoluteAssetDisplayType } from 'components/publicWebsiteV2/util/useCroppedBottomAbsoluteAssets';
import {
  appendWidthParam,
  DevicePartial,
  getMobileSize,
  getTabletSize,
} from 'components/publicWebsiteV2/util/getRelativeImgSize';
import type { FullComponentFontView, PartialComponentFontView, MediaQueryType } from './types';
import { Z_INDEX_MAP } from '../zIndexConstants';

/** fills font properties with values from fallback if missing */
export const fillFontProperties = (
  defaultValues: FullComponentFontView,
  values?: PartialComponentFontView
): FullComponentFontView => {
  return {
    fontFamily: values?.font_family?.name || values?.name || defaultValues.fontFamily,
    isCursive: values?.font_family?.cursive || values?.cursive || defaultValues.isCursive,
    fontSize: values?.font_size || defaultValues.fontSize,
    letterSpacing: values?.letter_spacing || defaultValues.letterSpacing,
    lineHeight: values?.line_height || defaultValues.lineHeight,
    textTransform: values?.text_transform || defaultValues.textTransform,
    color: values?.color || defaultValues.color,
    fontWeight: values?.font_weight || defaultValues.fontWeight,
  };
};

/**
 * Font weight sent as weight name
 *
 * Map according to OpenType specification:
 * https://docs.microsoft.com/en-us/typography/opentype/spec/os2#usweightclass
 */
export const mapWeightNameToNumericalValue = (
  n: WThemeComponentFontView.FontWeightEnum
): number => {
  switch (n) {
    case ('THIN' as unknown) as WThemeComponentFontView.FontWeightEnum:
      return 100;
    case ('EXTRALIGHT' as unknown) as WThemeComponentFontView.FontWeightEnum:
      return 200;
    case ('LIGHT' as unknown) as WThemeComponentFontView.FontWeightEnum:
      return 300;
    case ('NORMAL' as unknown) as WThemeComponentFontView.FontWeightEnum:
      return 400;
    case ('MEDIUM' as unknown) as WThemeComponentFontView.FontWeightEnum:
      return 500;
    case ('SEMIBOLD' as unknown) as WThemeComponentFontView.FontWeightEnum:
      return 600;
    case ('BOLD' as unknown) as WThemeComponentFontView.FontWeightEnum:
      return 700;
    case ('EXTRABOLD' as unknown) as WThemeComponentFontView.FontWeightEnum:
      return 800;
    case ('BLACK' as unknown) as WThemeComponentFontView.FontWeightEnum:
      return 900;
    case ('EXTRABLACK' as unknown) as WThemeComponentFontView.FontWeightEnum:
      return 950;
    default:
      return 400;
  }
};

export const mapTextTransformValue = (
  s: WThemeComponentFontView.TextTransformEnum
): React.CSSProperties['textTransform'] => {
  switch (s) {
    case ('CAPITALIZE' as unknown) as WThemeComponentFontView.TextTransformEnum:
      return 'capitalize';
    case ('UPPERCASE' as unknown) as WThemeComponentFontView.TextTransformEnum:
      return 'uppercase';
    case ('LOWERCASE' as unknown) as WThemeComponentFontView.TextTransformEnum:
      return 'lowercase';
    default:
      return 'none';
  }
};

export const getResponsiveFontSizeStyle = ({
  fontSize,
  mediaQuery,
}: {
  fontSize: number;
  mediaQuery: MediaQueryType;
}) => {
  return `
    ${mediaQuery.MOBILE} {
      font-size: ${Math.ceil(fontSize * 0.8)}px;
    }`;
};

// TODO: Remove after baby conversion
export const mapFullFontViewToSerializedStyles = ({
  fontStyles: f,
  responsiveFontSize,
  mediaQuery,
  excludeFontSize,
}: {
  fontStyles: FullComponentFontView;
  responsiveFontSize?: boolean;
  mediaQuery: MediaQueryType;
  excludeFontSize?: boolean;
}): SerializedStyles => {
  return css`
    font-family: '${f.fontFamily}';
    color: #${f.color};
    font-weight: ${mapWeightNameToNumericalValue(f.fontWeight)};
    text-transform: ${f.isCursive ? 'none' : mapTextTransformValue(f.textTransform)};
    line-height: ${f.lineHeight};
    letter-spacing: ${Number(f.letterSpacing) > 0 ? `${f.letterSpacing}px` : 'normal'};
    ${!excludeFontSize && `font-size: ${f.fontSize}px;`}
    ${
      !excludeFontSize &&
      responsiveFontSize &&
      getResponsiveFontSizeStyle({ fontSize: f.fontSize, mediaQuery })
    }
  `;
};
// TODO: Remove after baby conversions
export const mapFontValuesToSerializedStyles = ({
  fallback,
  partial,
  responsiveFontSize,
  mediaQuery,
  excludeFontSize,
}: {
  fallback: FullComponentFontView;
  partial?: PartialComponentFontView;
  responsiveFontSize?: boolean;
  mediaQuery: MediaQueryType;
  excludeFontSize?: boolean;
}): SerializedStyles => {
  return mapFullFontViewToSerializedStyles({
    fontStyles: fillFontProperties(fallback, partial),
    responsiveFontSize,
    mediaQuery,
    excludeFontSize,
  });
};

const mapLengthToCSS = (l?: WLengthView): string => {
  if (!l) {
    return '';
  }
  const { unit, value } = l;
  if (unit === (('PERCENTAGE' as unknown) as WLengthView.UnitEnum)) {
    return `${value}%;`;
  }
  if (unit === (('REM' as unknown) as WLengthView.UnitEnum)) {
    return `${value}rem`;
  }
  return `${value}px`;
};

type BaseAssetStyles = Pick<
  WAbsoluteAssetSizeView,
  | 'width'
  | 'z_index_key'
  | 'margin_top'
  | 'margin_bottom'
  | 'margin_right'
  | 'margin_left'
  | 'display'
>;

const mapBaseAssetStylesToCSS = (b: BaseAssetStyles) => {
  const { width, z_index_key, margin_top, margin_bottom, margin_left, margin_right, display } = b;
  return `
    ${width ? `width: ${mapLengthToCSS(width)}` : ''};
    ${z_index_key ? `z-index: ${Z_INDEX_MAP[z_index_key]};` : ''}
    ${margin_top ? `margin-top: ${mapLengthToCSS(margin_top)};` : ''}
    ${margin_bottom ? `margin-bottom: ${mapLengthToCSS(margin_bottom)};` : ''}
    ${margin_right ? `margin-right: ${mapLengthToCSS(margin_right)};` : ''}
    ${margin_left ? `margin-left: ${mapLengthToCSS(margin_left)};` : ''}
    display: ${display || 'block'};
  `;
};

const mapAbsoluteAssetSizeViewToCSS = (abs: WAbsoluteAssetSizeView) => {
  const {
    width,
    z_index_key,
    margin_right,
    margin_left,
    margin_top,
    margin_bottom,
    top,
    right,
    bottom,
    left,
    display,
  } = abs;
  return `
    ${mapBaseAssetStylesToCSS({
      width,
      z_index_key,
      margin_top,
      margin_bottom,
      margin_left,
      margin_right,
      display,
    })}
    position: absolute;
    ${top ? `top: ${mapLengthToCSS(top)};` : ''}
    ${bottom ? `bottom: ${mapLengthToCSS(bottom)};` : ''}
    ${right ? `right: ${mapLengthToCSS(right)};` : ''}
    ${left ? `left: ${mapLengthToCSS(left)};` : ''}
  `;
};

const mapInFlowStylesToCSS = (inf?: WInFlowAssetSizeView) => {
  if (!inf) return '';
  const {
    width,
    height,
    z_index_key,
    margin_top,
    margin_bottom,
    margin_right,
    margin_left,
    display,
  } = inf;
  const styles = `
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      height: ${height?.value}px;
      ${mapBaseAssetStylesToCSS({
        width,
        z_index_key,
        margin_top,
        margin_bottom,
        margin_right,
        margin_left,
        display,
      })}
  `;
  return styles;
};

export const mapAbsoluteAssetViewToStyledImg = (
  a: WResponsiveAssetSizeViewWAbsoluteAssetSizeView,
  adjustedDisplay: CroppedAbsoluteAssetDisplayType = { display: true }
): StyledComponent<
  {
    theme?: Theme | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    as?: React.ElementType<any> | undefined;
  },
  React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
  Record<string, unknown>
> => {
  const { desktop, tablet, mobile, custom_breakpoints } = a || {};

  let modifiedTablet = tablet;
  if (desktop?.width?.unit === (('PIXEL' as unknown) as WLengthView.UnitEnum) && !tablet?.width) {
    modifiedTablet = {
      ...modifiedTablet,
      width: {
        unit: ('PIXEL' as unknown) as WLengthView.UnitEnum,
        value: getTabletSize(desktop?.width.value),
      },
    };
  }
  let modifiedMobile = mobile;
  if (desktop?.width?.unit === (('PIXEL' as unknown) as WLengthView.UnitEnum) && !mobile?.width) {
    modifiedMobile = {
      ...modifiedMobile,
      width: {
        unit: ('PIXEL' as unknown) as WLengthView.UnitEnum,
        value: getMobileSize(desktop?.width.value),
      },
    };
  }
  return styled.img`
    ${desktop && mapAbsoluteAssetSizeViewToCSS(desktop)}
    ${({ theme }) =>
      modifiedTablet &&
      `${theme.MEDIA_QUERY.TABLET} {${mapAbsoluteAssetSizeViewToCSS(modifiedTablet)}}`}
    ${({ theme }) =>
      modifiedMobile &&
      `${theme.MEDIA_QUERY.MOBILE} {${mapAbsoluteAssetSizeViewToCSS(modifiedMobile)}}`}
    ${
      custom_breakpoints
        ? css`
            ${Object.keys(custom_breakpoints).map(
              q =>
                `@media (max-width: ${q}px) {${mapAbsoluteAssetSizeViewToCSS(
                  custom_breakpoints[(q as unknown) as keyof typeof custom_breakpoints]
                )}}`
            )}
          `
        : ''
    }
    ${adjustedDisplay.display ? '' : `display: none;`}
    ${
      adjustedDisplay.crop
        ? `
      height: ${adjustedDisplay.crop.height}px;
      object-fit: cover;
      object-position: 0% 0%;
    `
        : ''
    }
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
  `;
};

export const mapInFlowAssetViewToStyledImg = (
  assetPlacement: 'before' | 'after',
  inFlowAsset?: WInFlowThemeComponentAssetView
) => {
  const { desktop, tablet, mobile } = inFlowAsset?.style?.responsive_size || {};
  const desktopWidth = desktop?.width?.value;
  const tabletWidth = tablet?.width?.value;
  const mobileWidth = mobile?.width?.value;

  return styled.img`
    display: flex;
    justify-content: center;
    margin: ${assetPlacement === 'after' ? SPACING.LG : 'auto'} auto
      ${assetPlacement === 'before' ? SPACING.LG : 'auto'};
    width: ${desktopWidth ? `${desktopWidth}px` : '90%'};
    ${tabletWidth ? `${MEDIA_QUERY.TABLET} {width: ${tabletWidth}px}` : ''}
    ${mobileWidth ? `${MEDIA_QUERY.MOBILE} {width: ${mobileWidth}px}` : ''}
  `;
};

export const mapPseudoElementToCSS = (
  e: WInFlowThemeComponentAssetView | undefined,
  device?: DevicePartial,
  containerWidth?: number,
  mediaQuery = (MEDIA_QUERY as unknown) as MediaQueryType
): string => {
  if (!e) return '';

  const {
    image_url,
    // option_type_value, TODO
    style,
  } = e;

  if (!style?.responsive_size) return '';

  const { desktop, tablet, mobile, custom_breakpoints } = style?.responsive_size || {};
  const { height, width } = desktop || {};
  const aspectRatio = height && width?.value ? height?.value / width?.value : 1;
  let modifiedTablet = tablet;
  if (width?.unit === (('PIXEL' as unknown) as WLengthView.UnitEnum) && !tablet?.width) {
    const tabletWidth = Math.floor((width?.value * 2) / 3);
    const tabletHeight = Math.floor(aspectRatio * tabletWidth);
    modifiedTablet = {
      ...modifiedTablet,
      width: { unit: ('PIXEL' as unknown) as WLengthView.UnitEnum, value: tabletWidth },
      height: { unit: ('PIXEL' as unknown) as WLengthView.UnitEnum, value: tabletHeight },
    };
  }
  let modifiedMobile = mobile;
  if (width?.unit === (('PIXEL' as unknown) as WLengthView.UnitEnum) && !mobile?.width) {
    const mobileWidth = Math.floor(width?.value / 3);
    const mobileHeight = Math.floor(aspectRatio * mobileWidth);
    modifiedMobile = {
      ...modifiedMobile,
      width: { unit: ('PIXEL' as unknown) as WLengthView.UnitEnum, value: mobileWidth },
      height: { unit: ('PIXEL' as unknown) as WLengthView.UnitEnum, value: mobileHeight },
    };
  }
  const orderedBreakpoints = Object.keys(custom_breakpoints || {}).sort();
  const otherBPDesktop = _pick(
    custom_breakpoints,
    orderedBreakpoints.filter(b => Number(b) > 1200)
  );
  const otherBPDesktopToTablet = _pick(
    custom_breakpoints,
    orderedBreakpoints.filter(b => Number(b) <= 1200 && Number(b) > 992)
  );
  const otherBPTabletToMobile = _pick(
    custom_breakpoints,
    orderedBreakpoints.filter(b => Number(b) <= 992 && Number(b) > 767)
  );
  const otherBPMobile = _pick(
    custom_breakpoints,
    orderedBreakpoints.filter(b => Number(b) <= 767)
  );

  const getCustomBreakpointStyles = (b: Partial<{ [key: string]: WInFlowAssetSizeView }>) => {
    if (Object.keys(b).length === 0) {
      return '';
    }
    return `
      ${Object.keys(b).map(
        q =>
          `@media (max-width: ${q}px) {${mapInFlowStylesToCSS(
            b[(q as unknown) as keyof typeof b]
          )}}`
      )}
    `;
  };

  let imageUrlWithParams;

  if (containerWidth && style) {
    imageUrlWithParams = appendWidthParam({
      src: image_url,
      width: containerWidth,
      device,
      style,
    });
  }

  return `
    ${image_url ? `background-image: url(${imageUrlWithParams || image_url});` : ''}
    content: ' ';
    ${getCustomBreakpointStyles(otherBPDesktop)}
    ${mapInFlowStylesToCSS(desktop)}
    ${getCustomBreakpointStyles(otherBPDesktopToTablet)}
    ${modifiedTablet ? `${mediaQuery.TABLET} {${mapInFlowStylesToCSS(modifiedTablet)}}` : ''}
    ${getCustomBreakpointStyles(otherBPTabletToMobile)}
    ${modifiedMobile ? `${mediaQuery.MOBILE} {${mapInFlowStylesToCSS(modifiedMobile)}}` : ''}
    ${getCustomBreakpointStyles(otherBPMobile)}
  `;
};

export const mapInFlowAssetToCSS = (
  i?: WInFlowThemeComponentAssetSummaryView,
  device?: DevicePartial,
  containerWidth?: number,
  mediaQuery?: MediaQueryType
): string => {
  if (!i) return '';
  const { orientation, before, after } = i;
  const displayStyle = `display: ${
    orientation ===
    (('HORIZONTAL' as unknown) as WInFlowThemeComponentAssetSummaryView.OrientationEnum)
      ? 'inline-block'
      : 'block'
  }`;
  return `
    ${displayStyle};
    :before {
      ${displayStyle};
      margin-left: auto;
      margin-right: auto;
      vertical-align: middle;
      position: relative;
      ${mapPseudoElementToCSS(before, device, containerWidth, mediaQuery)}
    }

    :after {
      ${displayStyle};
      margin-left: auto;
      margin-right: auto;
      vertical-align: middle;
      ${mapPseudoElementToCSS(after, device, containerWidth, mediaQuery)}
    }
  `;
};

export const mapBackgroundToInlineStyle = (b?: WThemeComponentBackgroundView): CSSProperties => {
  if (!b) return {};
  const { background_color, background_image } = b;
  const { vertical_repeat, image_url } = background_image || {};
  const fixed = false;

  const mapRepeatToCssProperty = (v: WThemeComponentBackgroundImageView.VerticalRepeatEnum) => {
    if (v === (('NO_REPEAT' as unknown) as WThemeComponentBackgroundImageView.VerticalRepeatEnum)) {
      return 'no-repeat';
    }
    return ((v as unknown) as string).toLowerCase();
  };

  const styleObject: CSSProperties = {
    backgroundColor: background_color?.value ? `#${background_color.value}` : 'transparent',
    backgroundRepeat: `no-repeat ${mapRepeatToCssProperty(
      vertical_repeat || (('' as unknown) as WThemeComponentBackgroundImageView.VerticalRepeatEnum)
    )}`,
    backgroundSize: `100% auto`,
    backgroundAttachment: fixed ? 'fixed' : 'inherit',
  };

  if (background_image) {
    styleObject.backgroundImage = `url(${image_url})`;
  }

  return styleObject;
};

export const mapFontColorToBgOverlayWithOpacity = (color: string): string => {
  const asRgb = hexToRgb(color);
  const isDark = isDarkColor(color);
  return `rgba(${asRgb?.r}, ${asRgb?.g}, ${asRgb?.b}, ${isDark ? '.08' : '.16'})`;
};
