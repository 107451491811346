import React from 'react';
import { css } from '@emotion/react';
import { MEDIA_QUERY } from '@zola/zola-ui/src/styles/emotion';
import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';

import { mapFontValuesToSerializedStyles } from 'components/publicWebsiteV2/util/mappers';

import { getDate, isSameDate, isAfterDate } from '@zola-helpers/client/dist/es/util/dateUtils';
import pluralize from '@zola-helpers/client/dist/es/transformers/pluralize';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import getZolaImageFromUuid from '@zola-helpers/client/dist/es/util/getZolaImageFromUuid';

import {
  Container,
  Title,
  ArrivalDate,
  Greeting,
  HeroImage,
  HeaderSection,
} from './BabyRegistryHeader.styles';

dayjs.extend(utc);

type BabyRegistryHeaderProps = {
  title?: string;
  arrivalDate?: Date;
  greeting?: string;
  heroImageUuid?: string;
};

const BabyRegistryHeader = ({
  title,
  arrivalDate,
  greeting,
  heroImageUuid,
}: BabyRegistryHeaderProps): JSX.Element => {
  const {
    state: {
      wedding,
      components: { globalHeaderFontValues, globalBodyFontValues },
    },
  } = useWebsiteThemeContext();
  const { public_theme_v2 } = wedding || {};

  const titleFontStyles = public_theme_v2?.components?.NAV?.fonts?.HEADER?.[2];
  const TitleStyles = css`
    ${mapFontValuesToSerializedStyles({
      fallback: globalHeaderFontValues,
      partial: titleFontStyles,
      responsiveFontSize: true,
      mediaQuery: MEDIA_QUERY,
    })}
    text-transform: none;
  `;

  const bodyFontStyles = public_theme_v2?.components?.NAV?.fonts?.BODY?.[1];
  const BodyStyles = css`
    ${mapFontValuesToSerializedStyles({
      fallback: globalBodyFontValues,
      partial: bodyFontStyles,
      responsiveFontSize: true,
      mediaQuery: MEDIA_QUERY,
    })}
    text-transform: none;
  `;

  const formatDate = (d?: string | Date): string | undefined => {
    if (d) {
      return dayjs.utc(d).format('MMMM D, YYYY');
    }
    return undefined;
  };

  let daysToGo = null;
  if (arrivalDate) {
    const now = getDate();
    const diff = arrivalDate ? dayjs(arrivalDate).diff(now, 'day') : 0;

    const dayOf = isSameDate(now, arrivalDate);
    const dateIsPast = isAfterDate(now, arrivalDate);

    if (dayOf) {
      daysToGo = null;
    }
    if (dateIsPast) {
      daysToGo = null;
    }
    if (diff > 0) {
      daysToGo = ` (${pluralize('day', 'days', diff, true)} to go)`;
    }
  }

  return (
    <Container>
      {heroImageUuid && (
        <HeaderSection>
          <HeroImage src={getZolaImageFromUuid(heroImageUuid)} />
        </HeaderSection>
      )}
      <HeaderSection>
        {title && <Title css={TitleStyles}>{title}</Title>}
        {arrivalDate && (
          <ArrivalDate css={BodyStyles}>
            <strong>
              Arrival date {formatDate(arrivalDate)}
              {daysToGo}
            </strong>
          </ArrivalDate>
        )}
        {greeting && <Greeting css={BodyStyles}>{greeting}</Greeting>}
      </HeaderSection>
    </Container>
  );
};

export default BabyRegistryHeader;
