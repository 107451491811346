import React from 'react';
import Link from 'next/link';

// Context
import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';

// Styles
import COLORS from '@zola/zola-ui/src/styles/emotion/colorsBaby';
import { isDarkColor } from 'pages/publicStyleUtils/utils.styles';
import {
  NavLogo,
  NavLinkContainer,
  NavContainer,
  NavLinkItem,
  NavLinkItemCart,
} from './NavigationMenu.styles';

import HeaderCart from '../HeaderCart';

export type NavigationMenuProps = {
  className?: string;
  titleStyles?: any;
};

const NavigationMenu = ({ className, titleStyles }: NavigationMenuProps): JSX.Element => {
  const {
    state: {
      components: { backgroundColors },
      inPreview,
    },
  } = useWebsiteThemeContext();

  const isDarkMode = isDarkColor(backgroundColors.nav);

  return (
    <NavContainer className={className} inPreview={Boolean(inPreview)}>
      <NavLogo color={isDarkMode ? COLORS.WHITE_100 : COLORS.BLACK_100} />
      <NavLinkContainer css={titleStyles}>
        <NavLinkItem>
          <Link href="tel:1-855-918-2229">1-855-918-BABY</Link>
        </NavLinkItem>
        <NavLinkItemCart>
          <HeaderCart isDarkMode={isDarkMode} />
        </NavLinkItemCart>
      </NavLinkContainer>
    </NavContainer>
  );
};

export default NavigationMenu;
