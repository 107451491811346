import type { WThemeComponentFontView } from '@zola/svc-web-api-ts-client';
import COLORS from '@zola/zola-ui/src/styles/emotion/colors';
import type { FullComponentFontView } from './util/mappers/types';

export const DEFAULT_HEADER_FONT_VALUES: FullComponentFontView = {
  fontFamily: 'Proxima Nova',
  isCursive: false,
  fontSize: 40,
  letterSpacing: 0,
  lineHeight: 1.6,
  textTransform: ('NONE' as unknown) as WThemeComponentFontView.TextTransformEnum,
  color: '000000',
  fontWeight: ('NORMAL' as unknown) as WThemeComponentFontView.FontWeightEnum,
};
export const DEFAULT_BODY_FONT_VALUES = {
  fontFamily: 'Proxima Nova',
  isCursive: false,
  fontSize: 20,
  letterSpacing: 0,
  lineHeight: 1.6,
  textTransform: ('NONE' as unknown) as WThemeComponentFontView.TextTransformEnum,
  color: '000000',
  fontWeight: ('NORMAL' as unknown) as WThemeComponentFontView.FontWeightEnum,
};
export const DEFAULT_ACCENT_COLOR = COLORS.NAVY.substring(1);

export const DEFAULT_BACKGROUND_COLOR = 'FFFFFF';
