import React, { FC } from 'react';
import { InView } from 'react-intersection-observer';

type InViewWrapperProps = {
  children: JSX.Element | JSX.Element[];
  minHeight?: number;
};

const InViewWrapper: FC<InViewWrapperProps> = ({ children, minHeight = 40 }) => {
  return (
    <div style={{ minHeight }}>
      <InView triggerOnce>
        {({ inView, ref }) => (
          <div ref={ref} style={{ width: '100%' }}>
            {inView && children}
          </div>
        )}
      </InView>
    </div>
  );
};

export default InViewWrapper;
