import styled from '@emotion/styled';
import { Page, ModuleTile, ModuleTileInner } from '@zola/zola-ui/src/components/Grid';
import SPACING from '@zola/zola-ui/src/styles/emotion/spacing';
import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQueryV2';

export const StyledPage = styled(Page)`
  margin-left: auto;
  margin-right: auto;
`;

export const GridTile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  picture,
  img {
    object-fit: cover;
    width: 100%;
    margin-bottom: -3px;
  }
`;

export const ContentTile = styled(ModuleTile)`
  ${ModuleTileInner} {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    flex-direction: column;
    row-gap: ${SPACING.S32};
    ${MEDIA_QUERY.DESKTOP} {
      padding: 89px;
    }
  }
`;
