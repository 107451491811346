import React, { RefObject } from 'react';
import { Global, css, ThemeProvider } from '@emotion/react';
import dynamic from 'next/dynamic';

// Types
import type { WPublicWeddingView } from '@zola/svc-web-api-ts-client';

// Util
import {
  WebsiteThemeContextType,
  WebsiteThemeContextProvider,
} from 'components/publicWebsiteV2/context';
import { mapBackgroundToInlineStyle } from 'components/publicWebsiteV2/util/mappers';
import { mapWeddingToComponents } from 'components/publicWebsiteV2/util/mappers/mapWeddingToComponents';

// TODO: Import a less file fonts. Will require moving WebsiteV2-specific fonts into their own file, then importing _fonts.less
import fontAssets from 'cards/stylesheets/_fonts.styles';
import { MEDIA_QUERY } from '@zola/zola-ui/src/styles/emotion';
import type { ZolaTheme } from '../../ZolaThemeProvider';
import { Container } from './PublicV2.styles';
import { MEDIA_QUERY_FIXED_DESKTOP, MEDIA_QUERY_FIXED_MOBILE } from './util/mediaQueryFixed';

const ModalRoot = dynamic(() => import('components/common/ModalRoot'));
const ToastsContainer = dynamic(() => import('components/common/ToastsContainer'));
const UnifiedNav = dynamic(() => import('components/common/header/UnifiedNav'));
const Metadata = dynamic(() => import('components/publicWebsiteV2/common/Metadata'));

export type PublicV2Props = Pick<
  WebsiteThemeContextType['state'],
  'pageDataContext' | 'enableInPreviewInteractions'
> & {
  className?: string;
  inPreview?: 'DESKTOP' | 'MOBILE';
  wedding?: WPublicWeddingView;
  previewContentRef?: RefObject<HTMLDivElement>;
  pageType?: string;
  showMetadata?: boolean;
  passcodeRequired?: boolean;
};

const PublicV2: React.FC<PublicV2Props> = ({
  wedding,
  inPreview,
  children,
  className,
  pageDataContext,
  previewContentRef,
  enableInPreviewInteractions,
  pageType,
  showMetadata = true,
}) => {
  const background = wedding?.public_theme_v2?.components?.GLOBAL?.background;

  const globalStyles = css`
    html {
      body {
        ${fontAssets}

        .modal-xl {
          width: 900px;
        }
        .modal-dialog {
          width: 900px;
          ${MEDIA_QUERY.TABLET} {
            width: 748px;
          }
          ${MEDIA_QUERY.MOBILE} {
            position: relative;
            width: auto;
            margin: 10px;
          }
        }

        .modal-close {
          color: #a8a8aa;
        }
        a {
          text-decoration: none;
        }
      }
    }
  `;
  const getMediaQuery = () => {
    if (inPreview) {
      if (inPreview === 'MOBILE') {
        return MEDIA_QUERY_FIXED_MOBILE;
      }
      return MEDIA_QUERY_FIXED_DESKTOP;
    }
    return MEDIA_QUERY;
  };
  const mediaQuery = getMediaQuery();
  const adjustedTheme = (ancestorTheme: ZolaTheme) =>
    ({
      ...ancestorTheme,
      MEDIA_QUERY: mediaQuery,
    } as ZolaTheme);
  const initialState = {
    components: mapWeddingToComponents({
      wedding,
      mediaQuery,
    }),
    highlightedComponents: [],
    wedding,
    inPreview,
    pageDataContext,
    enableInPreviewInteractions,
  };

  return (
    <Container
      id="public-v2"
      data-themekey={wedding?.public_theme_v2?.theme_group_key}
      className={className}
      ref={previewContentRef}
      style={mapBackgroundToInlineStyle(background)}
    >
      <Global styles={globalStyles} />
      {!inPreview && (
        <>
          {showMetadata && (
            <Metadata
              wedding={wedding as WPublicWeddingView}
              metaImageUrl={pageDataContext?.richPreviewImage}
              pageType={pageType}
            />
          )}
          <UnifiedNav isHidden />
          {/* @ts-expect-error ts-migrate(2739) FIXME: Type '{}' is missing the following properties from...  */}
          <ModalRoot />
          <ToastsContainer />
        </>
      )}
      <ThemeProvider theme={adjustedTheme}>
        <WebsiteThemeContextProvider initialState={initialState}>
          {children}
        </WebsiteThemeContextProvider>
      </ThemeProvider>
    </Container>
  );
};

export default PublicV2;
