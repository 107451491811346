import React from 'react';

type ImageDetails = {
  url: string;
  minBreakpoint?: string;
  maxBreakpoint?: string;
  url2x?: string;
};

export type ImageType = {
  [type: string]: ImageDetails[];
};

// Generates the source elements to be nested within a picture element
const getSourceElements = (
  images: ImageDetails[],
  imgType: string,
  include2x = false
): React.ReactNode => {
  return images.map((image, index) => {
    const min = image.minBreakpoint ? `(min-width: ${image.minBreakpoint}px)` : ``;
    const max = image.maxBreakpoint ? `(max-width: ${image.maxBreakpoint}px)` : ``;
    const and = min && max ? `and` : ``;
    return (
      <source
        key={`grid-source-${imgType}-${index}`}
        type={`image/${imgType}`}
        srcSet={include2x ? `${image.url}, ${image.url2x} 2x` : image.url}
        media={`${min} ${and} ${max}`}
      />
    );
  });
};

export default getSourceElements;
