import { css } from '@emotion/react';

/*
 * ----------------------------------
 * -----  STOP - READ THIS !!!  -----
 * ----------------------------------
 *
 * This file contains all public website specific fonts, PLUS a copy of all fonts in "cards/stylesheets/_fonts.less".
 * (At least until we can update PublicV2 to directly import LESS)
 *
 * Adding a new font:
 *   - Add the new font declaration to "cards/stylesheets/_fonts.less".
 *   - Also add it to this file.
 *   - Upload the font files manually to the "/static-assets/fonts/" S3 bucket.
 *
 * Notes:
 *   - Please only add .otf / .ttf formats
 *   - Strip parentheses from font family names to support react inline styling
 */
const publicFontPath = 'https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/';

const fonts = css`
  // Abigail
  @font-face {
    font-family: 'Abigail';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}Abigail-Regular.ttf');
  }

  // Amira Madison
  @font-face {
    font-family: 'Amira Madison Script';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}Amira-Madison-Script.otf');
  }

  // August Roma
  @font-face {
    font-family: 'August Roma Script';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}AugustRomaScript-Regular.ttf');
  }

  @font-face {
    font-family: 'August Roma Serif';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}AugustRomaSerif-Regular.ttf');
  }

  // Autumn Melody
  @font-face {
    font-family: 'Autumn Melody';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}AutumnMelody-Regular.ttf');
  }

  // Avalanche
  @font-face {
    font-family: 'Avalanche';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}Avalanche.ttf');
  }

  // Butterskotch
  @font-face {
    font-family: 'Butterskotch';
    font-weight: 300;
    font-style: normal;
    src: url('${publicFontPath}Butterskotch-Light.otf');
  }
  @font-face {
    font-family: 'Butterskotch';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}Butterskotch-Regular.otf');
  }
  @font-face {
    font-family: 'Butterskotch';
    font-weight: 700;
    font-style: normal;
    src: url('${publicFontPath}Butterskotch-Bold.otf');
  }

  // Futura
  @font-face {
    font-family: 'Futura Light';
    font-weight: 300;
    font-style: normal;
    src: url('${publicFontPath}Futura-Light.ttf');
  }

  @font-face {
    font-family: 'Futura Book';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}FuturaBook-Regular.ttf');
  }


  // Hallelujah
  @font-face {
    font-family: 'HallelujahSerif';
    font-weight: 500;
    font-style: normal;
    src: url('${publicFontPath}HallelujahSerif.ttf');
  }

  // Kindred
  @font-face {
    font-family: 'Kindred';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}Kindred.otf');
  }
  @font-face {
    font-family: 'Kindred';
    font-weight: 700;
    font-style: normal;
    src: url('${publicFontPath}Kindred-Bold.otf');
  }

  @font-face {
    font-family: 'Kindred Rounded';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}KindredRounded.otf');
  }
  @font-face {
    font-family: 'Kindred Rounded';
    font-weight: 300;
    font-style: normal;
    src: url('${publicFontPath}KindredRounded-Light.otf');
  }
  @font-face {
    font-family: 'Kindred Rounded';
    font-weight: 700;
    font-style: normal;
    src: url('${publicFontPath}KindredRounded-Bold.otf');
  }

  // Kinfolk
  @font-face {
    font-family: 'Kinfolk';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}Kinfolk-Regular.otf');
  }

  // Macadamia
  @font-face {
    font-family: 'Macadamia Smooth';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}MacadamiaSmooth-Regular.otf');
  }

  @font-face {
    font-family: 'Macadamia Textured';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}MacadamiaTextured-Regular.otf');
  }

  @font-face {
    font-family: 'Mikela';
    font-weight: 300;
    font-style: normal;
    src: url('${publicFontPath}Mikela-Light.otf');
  }

  // Mikela
  @font-face {
    font-family: 'Mikela';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}Mikela.otf');
  }

  @font-face {
    font-family: 'Mikela';
    font-weight: 700;
    font-style: normal;
    src: url('${publicFontPath}Mikela-Bold.otf');
  }

  // Modernist
  @font-face {
    font-family: 'Modernist';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}Modernist-Regular.ttf');
  }


  // Mojito
  @font-face {
    font-family: 'Mojito';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}Mojito-Regular.otf');
  }
  @font-face {
    font-family: 'Mojito Textured';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}MojitoTextured.otf');
  }
  @font-face {
    font-family: 'Mojito Textured';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}MojitoTextured-Oblique.otf');
  }

  // Oxford
  @font-face {
    font-family: 'Oxford';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}Oxford-Regular.ttf');
  }
  @font-face {
    font-family: 'Oxford';
    font-weight: 300;
    font-style: normal;
    src: url('${publicFontPath}Oxford-Light.ttf');
  }
  @font-face {
    font-family: 'Oxford';
    font-weight: 700;
    font-style: normal;
    src: url('${publicFontPath}Oxford-Bold.ttf');
  }

  // Sauvage
  @font-face {
    font-family: 'Sauvage';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}Sauvage-Regular.otf');
  }

  // Adora Bouton
  @font-face {
    font-family: 'Adora Bouton';
    font-weight: 400;
    src: url('${publicFontPath}Adora-Bouton.otf');
  }

  // Aston Script
  @font-face {
    font-family: 'Aston Script';
    font-weight: 400;
    src: url('${publicFontPath}AstonScript-Regular.otf');
  }

  // Alex Brush
  @font-face {
    font-family: 'Alex Brush';
    font-weight: 400;
    src: url('${publicFontPath}AlexBrush-Regular.ttf');
  }

  // Aleo
  @font-face {
    font-family: 'Aleo';
    font-weight: 300;
    src: url('${publicFontPath}Aleo-Light.eot');
    src: url('${publicFontPath}Aleo-Light.eot?#iefix') format('embedded-opentype'),
    url('${publicFontPath}Aleo-Light.otf') format('truetype');
  }

  @font-face {
    font-family: 'Aleo';
    font-weight: 300;
    font-style: italic;
    src: url('${publicFontPath}Aleo-LightItalic.eot');
    src: url('${publicFontPath}Aleo-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('${publicFontPath}Aleo-LightItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Aleo';
    font-weight: 400;
    src: url('${publicFontPath}Aleo-Regular.eot');
    src: url('${publicFontPath}Aleo-Regular.eot?#iefix') format('embedded-opentype'),
    url('${publicFontPath}Aleo-Regular.otf') format('truetype');
  }

  @font-face {
    font-family: 'Aleo';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}Aleo-Italic.eot');
    src: url('${publicFontPath}Aleo-Italic.eot?#iefix') format('embedded-opentype'),
    url('${publicFontPath}Aleo-Italic.otf') format('truetype');
  }

  @font-face {
    font-family: 'Aleo';
    font-weight: 700;
    src: url('${publicFontPath}Aleo-Bold.eot');
    src: url('${publicFontPath}Aleo-Bold.eot?#iefix') format('embedded-opentype'),
    url('${publicFontPath}Aleo-Bold.otf') format('truetype');
  }

  @font-face {
    font-family: 'Aleo';
    font-weight: 700;
    font-style: italic;
    src: url('${publicFontPath}Aleo-BoldItalic.eot');
    src: url('${publicFontPath}Aleo-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('${publicFontPath}Aleo-BoldItalic.ttf') format('truetype');
  }

  // Alexander Lettering
  @font-face {
    font-family: 'Alexander Lettering';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}Alexander-Lettering.eot');
    src: url('${publicFontPath}Alexander-Lettering.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Alexander-Lettering.woff') format('woff'),
      url('${publicFontPath}Alexander-Lettering.woff2') format('woff2'),
      url('${publicFontPath}Alexander-Lettering.ttf') format('truetype');
  }

  // Alfa Slab One

  @font-face {
    font-family: 'Alfa Slab One';
    font-weight: 400;
    src: url('${publicFontPath}AlfaSlabOne-Regular.ttf')
  }

  // Allura
  @font-face {
    font-family: 'Allura';
    font-weight: 400;
    src: url('${publicFontPath}Allura-Regular.ttf');
  }

  // Alpharush
  @font-face {
    font-family: 'Alpharush';
    src: url('${publicFontPath}Alpharush.eot');
    src: url('${publicFontPath}Alpharush.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Alpharush.woff2') format('woff2'),
      url('${publicFontPath}Alpharush.woff') format('woff'),
      url('${publicFontPath}Alpharush.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  // Amaryllis
  @font-face {
    font-family: 'Amaryllis';
    src: url('${publicFontPath}Amaryllis.eot');
    src: url('${publicFontPath}Amaryllis.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Amaryllis.woff2') format('woff2'),
      url('${publicFontPath}Amaryllis.woff') format('woff'),
      url('${publicFontPath}Amaryllis.otf') format('opentype'),
      url('${publicFontPath}Amaryllis.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  // Archane
  @font-face {
    font-family: 'Archane';
    src: url('${publicFontPath}Archane.eot');
    src: url('${publicFontPath}Archane.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Archane.woff2') format('woff2'),
      url('${publicFontPath}Archane.woff') format('woff'),
      url('${publicFontPath}Archane.otf') format('opentype'),
      url('${publicFontPath}Archane.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  // Argentinian Nights ( this font is also loaded by core css)
  @font-face {
    font-family: 'Argentinian Nights';
    font-weight: 400;
    src: url('${publicFontPath}ArgentinianNights.ttf');
  }

  // Arusher
  @font-face {
    font-family: 'Arusher Sans';
    src: url('${publicFontPath}Arusher-Sans.eot');
    src: url('${publicFontPath}Arusher-Sans.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Arusher-Sans.woff2') format('woff2'),
      url('${publicFontPath}Arusher-Sans.woff') format('woff'),
      url('${publicFontPath}Arusher-Sans.otf') format('opentype'),
      url('${publicFontPath}Arusher-Sans.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Arusher Script';
    src: url('${publicFontPath}Arusher-Script.eot');
    src: url('${publicFontPath}Arusher-Script.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Arusher-Script.woff2') format('woff2'),
      url('${publicFontPath}Arusher-Script.woff') format('woff'),
      url('${publicFontPath}Arusher-Script.otf') format('opentype'),
      url('${publicFontPath}Arusher-Script.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  // Arvo
  @font-face {
    font-family: 'Arvo';
    font-weight: 400;
    src: url('${publicFontPath}Arvo-Regular.ttf');
  }

  @font-face {
    font-family: 'Arvo';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}Arvo-Italic.ttf');
  }

  @font-face {
    font-family: 'Arvo';
    font-weight: 700;
    src: url('${publicFontPath}Arvo-Bold.ttf');
  }

  @font-face {
    font-family: 'Arvo';
    font-weight: 700;
    font-style: italic;
    src: url('${publicFontPath}Arvo-BoldItalic.ttf');
  }

  // Astoria Sans
  @font-face {
    font-family: 'Astoria Sans';
    font-weight: 300;
    src: url('${publicFontPath}AstoriaSans-Light.otf');
  }

  // Auteur
  @font-face {
    font-family: 'Auteur Script';
    font-weight: 400;
    src: url('${publicFontPath}AuteurScript-Regular.otf');
  }

  // Bad Script
  @font-face {
    font-family: 'Bad Script';
    font-weight: 400;
    src: url('${publicFontPath}BadScript-Regular.ttf');
  }

  // * Bellanaisa
  @font-face {
    font-family: 'Bellanaisa';
    font-style: normal;
    font-weight: 700;
    src: url('${publicFontPath}Bellanaisa.ttf'),
    url('${publicFontPath}Bellanaisa.eot') format('eot'),
    url('${publicFontPath}Bellanaisa.woff') format('woff'),
    url('${publicFontPath}Bellanaisa.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Bellanaisa';
    font-style: normal;
    font-weight: 400;
    src: url('${publicFontPath}Bellanaisa.ttf'),
    url('${publicFontPath}Bellanaisa.eot') format('eot'),
    url('${publicFontPath}Bellanaisa.woff') format('woff'),
    url('${publicFontPath}Bellanaisa.woff2') format('woff2');
  }

  // Ball Pen
  @font-face {
    font-family: 'Ball-Pen';
    font-weight: 400;
    src: url('${publicFontPath}Ball-Pen-Regular.ttf');
  }

  @font-face {
    font-family: 'Ball-Pen-Extra';
    font-weight: 400;
    src: url('${publicFontPath}Ball-Pen-Extra.ttf');
  }

  // Bagnard
  @font-face {
    font-family: 'Bagnard';
    src: url('${publicFontPath}Bagnard-Regular.woff2') format('woff2'),
        url('${publicFontPath}Bagnard-Regular.woff') format('woff'),
        url('${publicFontPath}Bagnard-Regular.otf') format('opentype'),
        url('${publicFontPath}Bagnard-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  // Barlow
  @font-face {
    font-family: 'Barlow';
    font-weight: 100;
    src: url('${publicFontPath}Barlow-Thin.ttf');
  }

  @font-face {
    font-family: 'Barlow';
    font-weight: 100;
    font-style: italic;
    src: url('${publicFontPath}Barlow-ThinItalic.ttf');
  }
  @font-face {
    font-family: 'Barlow';
    font-weight: 200;
    src: url('${publicFontPath}Barlow-ExtraLight.ttf');
  }

  @font-face {
    font-family: 'Barlow';
    font-weight: 200;
    font-style: italic;
    src: url('${publicFontPath}Barlow-ExtraLightItalic.ttf');
  }
  @font-face {
    font-family: 'Barlow';
    font-weight: 300;
    src: url('${publicFontPath}Barlow-Light.ttf');
  }

  @font-face {
    font-family: 'Barlow';
    font-weight: 300;
    font-style: italic;
    src: url('${publicFontPath}Barlow-LightItalic.ttf');
  }

  @font-face {
    font-family: 'Barlow';
    font-weight: 400;
    src: url('${publicFontPath}Barlow-Regular.ttf');
  }

  @font-face {
    font-family: 'Barlow';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}Barlow-Italic.ttf');
  }

  @font-face {
    font-family: 'Barlow';
    font-weight: 500;
    src: url('${publicFontPath}Barlow-Medium.ttf');
  }

  @font-face {
    font-family: 'Barlow';
    font-weight: 500;
    font-style: italic;
    src: url('${publicFontPath}Barlow-MediumItalic.ttf');
  }

  @font-face {
    font-family: 'Barlow';
    font-weight: 600;
    src: url('${publicFontPath}Barlow-SemiBold.ttf');
  }

  @font-face {
    font-family: 'Barlow';
    font-weight: 600;
    font-style: italic;
    src: url('${publicFontPath}Barlow-SemiBoldItalic.ttf');
  }

  @font-face {
    font-family: 'Barlow';
    font-weight: 700;
    src: url('${publicFontPath}Barlow-Bold.ttf');
  }

  @font-face {
    font-family: 'Barlow';
    font-weight: 700;
    font-style: italic;
    src: url('${publicFontPath}Barlow-BoldItalic.ttf');
  }

  @font-face {
    font-family: 'Barlow';
    font-weight: 800;
    src: url('${publicFontPath}Barlow-ExtraBold.ttf');
  }

  @font-face {
    font-family: 'Barlow';
    font-weight: 800;
    font-style: italic;
    src: url('${publicFontPath}Barlow-ExtraBoldItalic.ttf');
  }

  @font-face {
    font-family: 'Barlow';
    font-weight: 900;
    src: url('${publicFontPath}Barlow-Black.ttf');
  }

  @font-face {
    font-family: 'Barlow';
    font-weight: 900;
    font-style: italic;
    src: url('${publicFontPath}Barlow-BlackItalic.ttf');
  }



  // Barlow Condensed
  @font-face {
    font-family: 'Barlow Condensed';
    font-weight: 100;
    src: url('${publicFontPath}BarlowCondensed-Thin.ttf'); }
  @font-face {
    font-family: 'Barlow Condensed';
    font-weight: 100;
    font-style: italic;
    src: url('${publicFontPath}BarlowCondensed-ThinItalic.ttf'); }
  @font-face {
    font-family: 'Barlow Condensed';
    font-weight: 200;
    src: url('${publicFontPath}BarlowCondensed-ExtraLight.ttf'); }
  @font-face {
    font-family: 'Barlow Condensed';
    font-weight: 200;
    font-style: italic;
    src: url('${publicFontPath}BarlowCondensed-ExtraLightItalic.ttf'); }
  @font-face {
    font-family: 'Barlow Condensed';
    font-weight: 300;
    src: url('${publicFontPath}BarlowCondensed-Light.ttf'); }
  @font-face {
    font-family: 'Barlow Condensed';
    font-weight: 300;
    font-style: italic;
    src: url('${publicFontPath}BarlowCondensed-LightItalic.ttf'); }
  @font-face {
    font-family: 'Barlow Condensed';
    font-weight: 400;
    src: url('${publicFontPath}BarlowCondensed-Regular.ttf'); }
  @font-face {
    font-family: 'Barlow Condensed';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}BarlowCondensed-Italic.ttf'); }
  @font-face {
    font-family: 'Barlow Condensed';
    font-weight: 500;
    src: url('${publicFontPath}BarlowCondensed-Medium.ttf'); }
  @font-face {
    font-family: 'Barlow Condensed';
    font-weight: 500;
    font-style: italic;
    src: url('${publicFontPath}BarlowCondensed-MediumItalic.ttf'); }
  @font-face {
    font-family: 'Barlow Condensed';
    font-weight: 600;
    src: url('${publicFontPath}BarlowCondensed-SemiBold.ttf'); }
  @font-face {
    font-family: 'Barlow Condensed';
    font-weight: 600;
    font-style: italic;
    src: url('${publicFontPath}BarlowCondensed-SemiBoldItalic.ttf'); }
  @font-face {
    font-family: 'Barlow Condensed';
    font-weight: 700;
    src: url('${publicFontPath}BarlowCondensed-Bold.ttf'); }
  @font-face {
    font-family: 'Barlow Condensed';
    font-weight: 700;
    font-style: italic;
    src: url('${publicFontPath}BarlowCondensed-BoldItalic.ttf'); }
  @font-face {
    font-family: 'Barlow Condensed';
    font-weight: 800;
    src: url('${publicFontPath}BarlowCondensed-ExtraBold.ttf'); }
  @font-face {
    font-family: 'Barlow Condensed';
    font-weight: 800;
    font-style: italic;
    src: url('${publicFontPath}BarlowCondensed-ExtraBoldItalic.ttf'); }
  @font-face {
    font-family: 'Barlow Condensed';
    font-weight: 900;
    src: url('${publicFontPath}BarlowCondensed-Black.ttf'); }
  @font-face {
    font-family: 'Barlow Condensed';
    font-weight: 900;
    font-style: italic;
    src: url('${publicFontPath}BarlowCondensed-BlackItalic.ttf'); }


  // Barlow Semi Condensed
  @font-face {
    font-family: 'Barlow Semi Condensed';
    font-weight: 100;
    src: url('${publicFontPath}BarlowSemiCondensed-Thin.ttf');
  }

  @font-face {
    font-family: 'Barlow Semi Condensed';
    font-weight: 100;
    font-style: italic;
    src: url('${publicFontPath}BarlowSemiCondensed-ThinItalic.ttf');
  }
  @font-face {
    font-family: 'Barlow Semi Condensed';
    font-weight: 200;
    src: url('${publicFontPath}BarlowSemiCondensed-ExtraLight.ttf');
  }

  @font-face {
    font-family: 'Barlow Semi Condensed';
    font-weight: 200;
    font-style: italic;
    src: url('${publicFontPath}BarlowSemiCondensed-ExtraLightItalic.ttf');
  }
  @font-face {
    font-family: 'Barlow Semi Condensed';
    font-weight: 300;
    src: url('${publicFontPath}BarlowSemiCondensed-Light.ttf');
  }

  @font-face {
    font-family: 'Barlow Semi Condensed';
    font-weight: 300;
    font-style: italic;
    src: url('${publicFontPath}BarlowSemiCondensed-LightItalic.ttf');
  }

  @font-face {
    font-family: 'Barlow Semi Condensed';
    font-weight: 400;
    src: url('${publicFontPath}BarlowSemiCondensed-Regular.ttf');
  }

  @font-face {
    font-family: 'Barlow Semi Condensed';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}BarlowSemiCondensed-Italic.ttf');
  }

  @font-face {
    font-family: 'Barlow Semi Condensed';
    font-weight: 500;
    src: url('${publicFontPath}BarlowSemiCondensed-Medium.ttf');
  }

  @font-face {
    font-family: 'Barlow Semi Condensed';
    font-weight: 500;
    font-style: italic;
    src: url('${publicFontPath}BarlowSemiCondensed-MediumItalic.ttf');
  }

  @font-face {
    font-family: 'Barlow Semi Condensed';
    font-weight: 600;
    src: url('${publicFontPath}BarlowSemiCondensed-SemiBold.ttf');
  }

  @font-face {
    font-family: 'Barlow Semi Condensed';
    font-weight: 600;
    font-style: italic;
    src: url('${publicFontPath}BarlowSemiCondensed-SemiBoldItalic.ttf');
  }

  @font-face {
    font-family: 'Barlow Semi Condensed';
    font-weight: 700;
    src: url('${publicFontPath}BarlowSemiCondensed-Bold.ttf');
  }

  @font-face {
    font-family: 'Barlow Semi Condensed';
    font-weight: 700;
    font-style: italic;
    src: url('${publicFontPath}BarlowSemiCondensed-BoldItalic.ttf');
  }

  @font-face {
    font-family: 'Barlow Semi Condensed';
    font-weight: 800;
    src: url('${publicFontPath}BarlowSemiCondensed-ExtraBold.ttf');
  }

  @font-face {
    font-family: 'Barlow Semi Condensed';
    font-weight: 800;
    font-style: italic;
    src: url('${publicFontPath}BarlowSemiCondensed-ExtraBoldItalic.ttf');
  }

  @font-face {
    font-family: 'Barlow Semi Condensed';
    font-weight: 900;
    src: url('${publicFontPath}BarlowSemiCondensed-Black.ttf');
  }

  @font-face {
    font-family: 'Barlow Semi Condensed';
    font-weight: 900;
    font-style: italic;
    src: url('${publicFontPath}BarlowSemiCondensed-BlackItalic.ttf');
  }

  // Bentham
  @font-face {
    font-family: 'Bentham';
    font-weight: 400;
    src: url('${publicFontPath}Bentham-Regular.ttf');
  }

  // Billion Calligraphy
  @font-face {
    font-family: 'Billion Calligraphy';
    src: url('${publicFontPath}Billion-Calligraphy-Regular.woff2') format('woff2'),
        url('${publicFontPath}Billion-Calligraphy-Regular.woff') format('woff'),
        url('${publicFontPath}Billion-Calligraphy-Regular.otf') format('opentype'),
        url('${publicFontPath}Billion-Calligraphy-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  // Breezeblocks
  @font-face {
    font-family: 'Breezeblocks';
    src: url('${publicFontPath}Breezeblocks.eot');
    src: url('${publicFontPath}Breezeblocks.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Breezeblocks.woff2') format('woff2'),
      url('${publicFontPath}Breezeblocks.woff') format('woff'),
      url('${publicFontPath}Breezeblocks.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  // Borest Regular
  @font-face {
    font-family: 'Borest';
    font-weight: 400;
    src: url('${publicFontPath}Borest-Regular.otf');
  }


  // Bowlby One SC
  @font-face {
    font-family: 'Bowlby One SC';
    font-weight: 400;
    src: url('${publicFontPath}BowlbyOneSC-Regular.ttf');
  }

  // Bungee
  @font-face {
    font-family: 'Bungee';
    font-weight: 400;
    src: url('${publicFontPath}Bungee-Regular.ttf');
  }

  // Calson
  @font-face {
    font-family: 'Caslon Graphique D';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}CaslonGraD.eot');
    src: url('${publicFontPath}CaslonGraD.eot?#iefix') format('embedded-opentype'),
    url('${publicFontPath}CaslonGraD.woff2') format('woff2'),
    url('${publicFontPath}CaslonGraD.woff') format('woff'),
    url('${publicFontPath}CaslonGraD.ttf') format('truetype');
  }

  // Cardo
  @font-face {
    font-family: 'Cardo';
    font-weight: 400;
    src: url('${publicFontPath}Cardo-Regular.ttf');
  }

  @font-face {
    font-family: 'Cardo';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}Cardo-Italic.ttf');
  }

  @font-face {
    font-family: 'Cardo';
    font-weight: 700;
    src: url('${publicFontPath}Cardo-Bold.ttf');
  }

  // Clicker Script
  @font-face {
    font-family: 'Clicker Script';
    font-weight: 400;
    src: url('${publicFontPath}ClickerScript-Regular.ttf');
  }

  // * Chronos Serif
  @font-face {
    font-family: 'Chronos Serif';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}ChronosSerif-Filled.otf'),
        url('${publicFontPath}ChronosSerif-Filled.ttf') format('truetype'),
        url('${publicFontPath}ChronosSerif-Filled.eot') format('eot'),
        url('${publicFontPath}ChronosSerif-Filled.woff') format('woff'),
        url('${publicFontPath}ChronosSerif-Filled.woff2') format('woff2');
  }

  // Circular - Do not add additional weights or styles
  @font-face {
    font-family: 'circular';
    src: url('${publicFontPath}CircularXXWeb-Regular.woff2')
        format('woff2'),
      url('${publicFontPath}CircularXXWeb-Regular.woff')
        format('woff');
    font-weight: 400;
    font-style:normal;
    font-stretch:normal;
    font-display:swap;
  }

  @font-face {
    font-family: 'circular';
    src: url('${publicFontPath}CircularXXWeb-Medium.woff2')
        format('woff2'),
      url('${publicFontPath}CircularXXWeb-Medium.woff')
        format('woff');
    font-weight: 600;
    font-style:normal;
    font-stretch:normal;
    font-display:swap;
  }

  @font-face {
    font-family: 'circular';
    src: url('${publicFontPath}CircularXXWeb-MediumItalic.woff2')
        format('woff2'),
      url('${publicFontPath}CircularXXWeb-MediumItalic.woff')
        format('woff');
    font-weight: 400;
    font-style: italic;
    font-stretch:normal;
    font-display:swap;
  }

  // CircularXX TT
  @font-face {
    font-family: 'CircularXX TT';
    font-weight: 900;
    font-style: italic;
    src: url('${publicFontPath}CircularXXTT-ExtraBlackItalic.ttf');
  }
  @font-face {
    font-family: 'CircularXX TT';
    font-weight: 900;
    src: url('${publicFontPath}CircularXXTT-ExtraBlack.ttf');
  }
  @font-face {
    font-family: 'CircularXX TT';
    font-weight: 800;
    font-style: italic;
    src: url('${publicFontPath}CircularXXTT-BlackItalic.ttf');
  }
  @font-face {
    font-family: 'CircularXX TT';
    font-weight: 800;
    src: url('${publicFontPath}CircularXXTT-Black.ttf');
  }
  @font-face {
    font-family: 'CircularXX TT';
    font-weight: 700;
    font-style: italic;
    src: url('${publicFontPath}CircularXXTT-BoldItalic.ttf');
  }
  @font-face {
    font-family: 'CircularXX TT';
    font-weight: 700;
    src: url('${publicFontPath}CircularXXTT-Bold.ttf');
  }
  @font-face {
    font-family: 'CircularXX TT';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}CircularXXTT-BookItalic.ttf');
  }
  @font-face {
    font-family: 'CircularXX TT';
    font-weight: 400;
    src: url('${publicFontPath}CircularXXTT-Book.ttf');
  }
  @font-face {
    font-family: 'CircularXX TT';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}CircularXXTT-Italic.ttf');
  }
  @font-face {
    font-family: 'CircularXX TT';
    font-weight: 300;
    font-style: italic;
    src: url('${publicFontPath}CircularXXTT-LightItalic.ttf');
  }
  @font-face {
    font-family: 'CircularXX TT';
    font-weight: 300;
    src: url('${publicFontPath}CircularXXTT-Light.ttf');
  }
  @font-face {
    font-family: 'CircularXX TT';
    font-weight: 100;
    font-style: italic;
    src: url('${publicFontPath}CircularXXTT-ThinItalic.ttf');
  }
  @font-face {
    font-family: 'CircularXX TT';
    font-weight: 100;
    src: url('${publicFontPath}CircularXXTT-Thin.ttf');
  }

  // Coax
  @font-face {
    font-family: 'Coax';
    font-weight: 400;
    src: url('${publicFontPath}Coax-Regular.otf');
  }

  // Coldiac
  @font-face {
    font-family: 'Coldiac';
    src: url('${publicFontPath}Coldiac-Regular.woff2') format('woff2'),
        url('${publicFontPath}Coldiac-Regular.woff') format('woff'),
        url('${publicFontPath}Coldiac-Regular.otf') format('opentype'),
        url('${publicFontPath}Coldiac-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Coldiac';
    src: url('${publicFontPath}Coldiac-Italic.woff2') format('woff2'),
        url('${publicFontPath}Coldiac-Italic.woff') format('woff'),
        url('${publicFontPath}Coldiac-Italic.otf') format('opentype'),
        url('${publicFontPath}Coldiac-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  // Cookie
  @font-face {
    font-family: 'Cookie';
    font-weight: 400;
    src: url('${publicFontPath}Cookie-Regular.ttf');
  }

  // Cormorant Garamond
  @font-face {
    font-family: 'Cormorant Garamond';
    font-weight: 400;
    src: url('${publicFontPath}CormorantGaramond-Regular.ttf');
  }

  @font-face {
    font-family: 'Cormorant Garamond';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}CormorantGaramond-Italic.ttf');
  }

  @font-face {
    font-family: 'Cormorant Garamond';
    font-weight: 700;
    src: url('${publicFontPath}CormorantGaramond-Bold.ttf');
  }

  @font-face {
    font-family: 'Cormorant Garamond';
    font-weight: 700;
    font-style: italic;
    src: url('${publicFontPath}CormorantGaramond-BoldItalic.ttf');
  }

  @font-face {
    font-family: 'Cormorant Garamond';
    font-weight: 300;
    src: url('${publicFontPath}CormorantGaramond-Light.ttf');
  }

  @font-face {
    font-family: 'Cormorant Garamond';
    font-weight: 300;
    font-style: italic;
    src: url('${publicFontPath}CormorantGaramond-LightItalic.ttf');
  }

  @font-face {
    font-family: 'Cormorant Garamond';
    font-weight: 500;
    src: url('${publicFontPath}CormorantGaramond-Medium.ttf');
  }

  @font-face {
    font-family: 'Cormorant Garamond';
    font-weight: 500;
    font-style: italic;
    src: url('${publicFontPath}CormorantGaramond-MediumItalic.ttf');
  }

  @font-face {
    font-family: 'Cormorant Garamond';
    font-weight: 600;
    src: url('${publicFontPath}CormorantGaramond-SemiBold.ttf');
  }

  @font-face {
    font-family: 'Cormorant Garamond';
    font-weight: 600;
    font-style: italic;
    src: url('${publicFontPath}CormorantGaramond-SemiBoldItalic.ttf');
  }



  // Cormorant Infant
  @font-face {
    font-family: 'Cormorant Infant';
    font-weight: 300;
    src: url('${publicFontPath}CormorantInfant-Light.ttf');
  }

  @font-face {
    font-family: 'Cormorant Infant';
    font-weight: 300;
    font-style: italic;
    src: url('${publicFontPath}CormorantInfant-LightItalic.ttf');
  }

  @font-face {
    font-family: 'Cormorant Infant';
    font-weight: 400;
    src: url('${publicFontPath}CormorantInfant-Regular.ttf');
  }

  @font-face {
    font-family: 'Cormorant Infant';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}CormorantInfant-Italic.ttf');
  }

  @font-face {
    font-family: 'Cormorant Infant';
    font-weight: 500;
    src: url('${publicFontPath}CormorantInfant-Medium.ttf');
  }

  @font-face {
    font-family: 'Cormorant Infant';
    font-weight: 500;
    font-style: italic;
    src: url('${publicFontPath}CormorantInfant-MediumItalic.ttf');
  }

  @font-face {
    font-family: 'Cormorant Infant';
    font-weight: 600;
    src: url('${publicFontPath}CormorantInfant-SemiBold.ttf');
  }

  @font-face {
    font-family: 'Cormorant Infant';
    font-weight: 600;
    font-style: italic;
    src: url('${publicFontPath}CormorantInfant-SemiBoldItalic.ttf');
  }

  @font-face {
    font-family: 'Cormorant Infant';
    font-weight: 700;
    src: url('${publicFontPath}CormorantInfant-Bold.ttf');
  }

  @font-face {
    font-family: 'Cormorant Infant';
    font-weight: 700;
    font-style: italic;
    src: url('${publicFontPath}CormorantInfant-BoldItalic.ttf');
  }



  // Cormorant Upright
  @font-face {
    font-family: 'Cormorant Upright';
    font-weight: 300;
    src: url('${publicFontPath}CormorantUpright-Light.ttf');
  }
  @font-face {
    font-family: 'Cormorant Upright';
    font-weight: 400;
    src: url('${publicFontPath}CormorantUpright-Regular.ttf');
  }
  @font-face {
    font-family: 'Cormorant Upright';
    font-weight: 500;
    src: url('${publicFontPath}CormorantUpright-Medium.ttf');
  }
  @font-face {
    font-family: 'Cormorant Upright';
    font-weight: 600;
    src: url('${publicFontPath}CormorantUpright-SemiBold.ttf');
  }
  @font-face {
    font-family: 'Cormorant Upright';
    font-weight: 700;
    src: url('${publicFontPath}CormorantUpright-Bold.ttf');
  }



  // Crimson Text
  @font-face {
    font-family: 'Crimson Text';
    font-weight: 400;
    src: url('${publicFontPath}CrimsonText-Regular.ttf');
  }

  @font-face {
    font-family: 'Crimson Text';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}CrimsonText-Italic.ttf');
  }

  @font-face {
    font-family: 'Crimson Text';
    font-weight: 600;
    src: url('${publicFontPath}CrimsonText-SemiBold.ttf');
  }

  @font-face {
    font-family: 'Crimson Text';
    font-weight: 600;
    font-style: italic;
    src: url('${publicFontPath}CrimsonText-SemiBoldItalic.ttf');
  }

  @font-face {
    font-family: 'Crimson Text';
    font-weight: 700;
    src: url('${publicFontPath}CrimsonText-Bold.ttf');
  }

  @font-face {
    font-family: 'Crimson Text';
    font-weight: 700;
    font-style: italic;
    src: url('${publicFontPath}CrimsonText-BoldItalic.ttf');
  }

  // Cutive
  @font-face {
    font-family: 'Cutive';
    font-weight: 400;
    src: url('${publicFontPath}Cutive-Regular.ttf');
  }

  // Cutive Mono
  @font-face {
    font-family: 'Cutive Mono';
    font-weight: 400;
    src: url('${publicFontPath}CutiveMono-Regular.woff2') format('woff2'),
        url('${publicFontPath}CutiveMono-Regular.woff') format('woff'),
        url('${publicFontPath}CutiveMono-Regular.ttf') format('truetype');
  }

  // Damion
  @font-face {
    font-family: 'Damion';
    font-weight: 400;
    src: url('${publicFontPath}Damion-Regular.ttf');
  }

  // Duke Charming
  @font-face {
    font-family: 'Duke Charming';
    src: url('${publicFontPath}Duke-Charming.eot');
    src: url('${publicFontPath}Duke-Charming.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Duke-Charming.woff2') format('woff2'),
      url('${publicFontPath}Duke-Charming.woff') format('woff'),
      url('${publicFontPath}Duke-Charming.svg') format('svg'),
      url('${publicFontPath}Duke-Charming.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  // EB Garamond
  @font-face {
    font-family: 'EB Garamond';
    font-weight: 400;
    src: url('${publicFontPath}EBGaramond-Regular.ttf');
  }

  @font-face {
    font-family: 'EB Garamond';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}EBGaramond-Italic.ttf');
  }

  @font-face {
    font-family: 'EB Garamond';
    font-weight: 500;
    src: url('${publicFontPath}EBGaramond-Medium.ttf');
  }

  @font-face {
    font-family: 'EB Garamond';
    font-weight: 500;
    font-style: italic;
    src: url('${publicFontPath}EBGaramond-MediumItalic.ttf');
  }

  @font-face {
    font-family: 'EB Garamond';
    font-weight: 600;
    src: url('${publicFontPath}EBGaramond-SemiBold.ttf');
  }

  @font-face {
    font-family: 'EB Garamond';
    font-weight: 600;
    font-style: italic;
    src: url('${publicFontPath}EBGaramond-SemiBoldItalic.ttf');
  }

  @font-face {
    font-family: 'EB Garamond';
    font-weight: 700;
    src: url('${publicFontPath}EBGaramond-Bold.ttf');
  }

  @font-face {
    font-family: 'EB Garamond';
    font-weight: 700;
    font-style: italic;
    src: url('${publicFontPath}EBGaramond-BoldItalic.ttf');
  }

  @font-face {
    font-family: 'EB Garamond';
    font-weight: 800;
    src: url('${publicFontPath}EBGaramond-ExtraBold.ttf');
  }

  @font-face {
    font-family: 'EB Garamond';
    font-weight: 800;
    font-style: italic;
    src: url('${publicFontPath}EBGaramond-ExtraBoldItalic.ttf');
  }

  // Edgethorn
  @font-face {
    font-family: 'Edgethorn';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}Edgethorn-Italic.otf');
  }

  // Euphoria Script
  @font-face {
    font-family: 'Euphoria Script';
    font-weight: 400;
    src: url('${publicFontPath}EuphoriaScript-Regular.ttf');
  }

  // Ferly
  @font-face {
    font-family: 'Ferly';
    font-weight: 400;
    src: url('${publicFontPath}Ferly-Regular.otf');
  }

  // Fifty Fifty
  @font-face {
    font-family: 'Fifty Fifty';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}FiftyFifty-Italic.otf');
  }
  @font-face {
    font-family: 'Fifty Fifty';
    font-weight: 400;
    src: url('${publicFontPath}FiftyFifty-Regular.otf');
  }

  // Flatline Sans
  @font-face {
    font-family: 'Flatline Sans';
    font-weight: 200;
    src: url('${publicFontPath}FlatlineSans-ExtraLight.otf');
  }
  @font-face {
    font-family: 'Flatline Sans';
    font-weight: 200;
    font-style: italic;
    src: url('${publicFontPath}FlatlineSans-ExtraLightItalic.otf');
  }
  @font-face {
    font-family: 'Flatline Sans';
    font-weight: 300;
    src: url('${publicFontPath}FlatlineSans-Light.otf');
  }
  @font-face {
    font-family: 'Flatline Sans';
    font-weight: 300;
    font-style: italic;
    src: url('${publicFontPath}FlatlineSans-LightItalic.otf');
  }
  @font-face {
    font-family: 'Flatline Sans';
    font-weight: 400;
    src: url('${publicFontPath}FlatlineSans-Regular.otf');
  }
  @font-face {
    font-family: 'Flatline Sans';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}FlatlineSans-RegularItalic.otf');
  }
  @font-face {
    font-family: 'Flatline Sans';
    font-weight: 500;
    src: url('${publicFontPath}FlatlineSans-Medium.otf');
  }
  @font-face {
    font-family: 'Flatline Sans';
    font-weight: 500;
    font-style: italic;
    src: url('${publicFontPath}FlatlineSans-MediumItalic.otf');
  }
  @font-face {
    font-family: 'Flatline Sans';
    font-weight: 600;
    src: url('${publicFontPath}FlatlineSans-SemiBold.otf');
  }
  @font-face {
    font-family: 'Flatline Sans';
    font-weight: 600;
    font-style: italic;
    src: url('${publicFontPath}FlatlineSans-SemiBoldItalic.otf');
  }
  @font-face {
    font-family: 'Flatline Sans';
    font-weight: 700;
    src: url('${publicFontPath}FlatlineSans-Bold.otf');
  }
  @font-face {
    font-family: 'Flatline Sans';
    font-weight: 700;
    font-style: italic;
    src: url('${publicFontPath}FlatlineSans-BoldItalic.otf');
  }
  @font-face {
    font-family: 'Flatline Sans';
    font-weight: 800;
    src: url('${publicFontPath}FlatlineSans-ExtraBold.otf');
  }
  @font-face {
    font-family: 'Flatline Sans';
    font-weight: 800;
    font-style: italic;
    src: url('${publicFontPath}FlatlineSans-ExtraBoldItalic.otf');
  }
  @font-face {
    font-family: 'Flatline Sans';
    font-weight: 900;
    src: url('${publicFontPath}FlatlineSans-Heavy.otf');
  }
  @font-face {
    font-family: 'Flatline Sans';
    font-weight: 900;
    font-style: italic;
    src: url('${publicFontPath}FlatlineSans-HeavyItalic.otf');
  }

  // Flatline Serif
  @font-face {
    font-family: 'Flatline Serif';
    font-weight: 200;
    src: url('${publicFontPath}FlatlineSerif-ExtraLight.otf');
  }
  @font-face {
    font-family: 'Flatline Serif';
    font-weight: 200;
    font-style: italic;
    src: url('${publicFontPath}FlatlineSerif-ExtraLightItalic.otf');
  }
  @font-face {
    font-family: 'Flatline Serif';
    font-weight: 300;
    src: url('${publicFontPath}FlatlineSerif-Light.otf');
  }
  @font-face {
    font-family: 'Flatline Serif';
    font-weight: 300;
    font-style: italic;
    src: url('${publicFontPath}FlatlineSerif-LightItalic.otf');
  }
  @font-face {
    font-family: 'Flatline Serif';
    font-weight: 400;
    src: url('${publicFontPath}FlatlineSerif-Regular.otf');
  }
  @font-face {
    font-family: 'Flatline Serif';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}FlatlineSerif-RegularItalic.otf');
  }
  @font-face {
    font-family: 'Flatline Serif';
    font-weight: 500;
    src: url('${publicFontPath}FlatlineSerif-MediumItalic.otf');
  }
  @font-face {
    font-family: 'Flatline Serif';
    font-weight: 500;
    font-style: italic;
    src: url('${publicFontPath}FlatlineSerif-MediumItalic.otf');
  }
  @font-face {
    font-family: 'Flatline Serif';
    font-weight: 600;
    src: url('${publicFontPath}FlatlineSerif-SemiBold.otf');
  }
  @font-face {
    font-family: 'Flatline Serif';
    font-weight: 600;
    font-style: italic;
    src: url('${publicFontPath}FlatlineSerif-SemiBoldItalic.otf');
  }
  @font-face {
    font-family: 'Flatline Serif';
    font-weight: 700;
    src: url('${publicFontPath}FlatlineSerif-Bold.otf');
  }
  @font-face {
    font-family: 'Flatline Serif';
    font-weight: 700;
    font-style: italic;
    src: url('${publicFontPath}FlatlineSerif-BoldItalic.otf');
  }
  @font-face {
    font-family: 'Flatline Serif';
    font-weight: 800;
    src: url('${publicFontPath}FlatlineSerif-ExtraBold.otf');
  }
  @font-face {
    font-family: 'Flatline Serif';
    font-weight: 800;
    font-style: italic;
    src: url('${publicFontPath}FlatlineSerif-ExtraBoldItalic.otf');
  }
  @font-face {
    font-family: 'Flatline Serif';
    font-weight: 900;
    src: url('${publicFontPath}FlatlineSerif-Heavy.otf');
  }
  @font-face {
    font-family: 'Flatline Serif';
    font-weight: 900;
    font-style: italic;
    src: url('${publicFontPath}FlatlineSerif-HeavyItalic.otf');
  }

  // Floral Hearts
  @font-face {
    font-family: 'Floral Hearts';
    font-weight: 400;
    src: url('${publicFontPath}FloralHearts.ttf');
  }

  // Gatwick
  @font-face {
    font-family: 'Gatwick';
    src: url('${publicFontPath}Gatwick-Bold.eot');
    src: url('${publicFontPath}Gatwick-Bold.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Gatwick-Bold.woff2') format('woff2'),
      url('${publicFontPath}Gatwick-Bold.woff') format('woff'),
      url('${publicFontPath}Gatwick-Bold.otf') format('opentype'),
      url('${publicFontPath}Gatwick-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  // Georgia Ballpark
  @font-face {
    font-family: 'GeorgiaBallpark Script';
    src: url('${publicFontPath}GeorgiaBallpark-Script.eot');
    src: url('${publicFontPath}GeorgiaBallpark-Script.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}GeorgiaBallpark-Script.woff2') format('woff2'),
      url('${publicFontPath}GeorgiaBallpark-Script.woff') format('woff'),
      url('${publicFontPath}GeorgiaBallpark-Script.otf') format('opentype'),
      url('${publicFontPath}GeorgiaBallpark-Script.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'GeorgiaBallpark Serif';
    src: url('${publicFontPath}GeorgiaBallpark-Serif.eot');
    src: url('${publicFontPath}GeorgiaBallpark-Serif.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}GeorgiaBallpark-Serif.woff2') format('woff2'),
      url('${publicFontPath}GeorgiaBallpark-Serif.woff') format('woff'),
      url('${publicFontPath}GeorgiaBallpark-Serif.otf') format('opentype'),
      url('${publicFontPath}GeorgiaBallpark-Serif.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  // Gorgeous
  @font-face {
    font-family: 'Gorgeous Serif Font';
    src: url('${publicFontPath}Gorgeous.eot');
    src: url('${publicFontPath}Gorgeous.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Gorgeous.woff2') format('woff2'),
      url('${publicFontPath}Gorgeous.woff') format('woff'),
      url('${publicFontPath}Gorgeous.otf') format('opentype'),
      url('${publicFontPath}Gorgeous.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  // Goudy National Italic
  @font-face {
    font-family: 'Goudy National';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}GoudyNational-Italic.ttf') format('truetype'),
    url('${publicFontPath}GoudyNational-Italic.eot') format('eot'),
    url('${publicFontPath}GoudyNational-Italic.woff') format('woff');
  }

  // Goudy National Regular
  @font-face {
    font-family: 'Goudy National';
    font-weight: 400;
    src: url('${publicFontPath}GoudyNational-Regular.ttf'),
    url('${publicFontPath}GoudyNational-Regular.eot') format('eot'),
    url('${publicFontPath}GoudyNational-Regular.woff') format('woff');
  }

  // * Hatton
  @font-face {
    font-family: 'Hatton';
    font-style: normal;
    font-weight: 400;
    src: url('${publicFontPath}Hatton-Regular.otf'),
    url('${publicFontPath}Hatton-Regular.ttf') format('truetype'),
    url('${publicFontPath}Hatton-Regular.eot') format('eot'),
    url('${publicFontPath}Hatton-Regular.woff') format('woff'),
    url('${publicFontPath}Hatton-Regular.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Hatton';
    font-style: normal;
    font-weight: 600;
    src: url('${publicFontPath}Hatton-Semibold.otf'),
    url('${publicFontPath}Hatton-Semibold.ttf') format('truetype'),
    url('${publicFontPath}Hatton-Semibold.eot') format('eot'),
    url('${publicFontPath}Hatton-Semibold.woff') format('woff'),
    url('${publicFontPath}Hatton-Semibold.woff2') format('woff2');
  }

  // Homemade Apple
  @font-face {
    font-family: 'Homemade Apple';
    font-weight: 400;
    src: url('${publicFontPath}HomemadeApple-Regular.ttf');
  }

  // HV Clio
  @font-face {
    font-family: 'HV Clio';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}HVClio-RegularItalic.ttf');
  }
  @font-face {
    font-family: 'HV Clio';
    font-weight: 400;
    src: url('${publicFontPath}HVClio-Regular.ttf');
  }

  // IM Fell Double Pica
  @font-face {
    font-family: 'IM Fell Double Pica';
    font-weight: 400;
    src: url('${publicFontPath}IMFeDPrm28P.ttf');
  }

  @font-face {
    font-family: 'IM Fell Double Pica';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}IMFeDPit28P.ttf');
  }



  //Josefin  Sans
  @font-face {
    font-family: 'Josefin Sans';
    font-weight: 100;
    src: url('${publicFontPath}JosefinSans-Thin.ttf');
  }

  @font-face {
    font-family: 'Josefin Sans';
    font-weight: 100;
    font-style: italic;
    src: url('${publicFontPath}JosefinSans-ThinItalic.ttf');
  }

  @font-face {
    font-family: 'Josefin Sans';
    font-weight: 300;
    src: url('${publicFontPath}JosefinSans-Light.ttf');
  }

  @font-face {
    font-family: 'Josefin Sans';
    font-weight: 300;
    font-style: italic;
    src: url('${publicFontPath}JosefinSans-LightItalic.ttf');
  }

  @font-face {
    font-family: 'Josefin Sans';
    font-weight: 400;
    src: url('${publicFontPath}JosefinSans-Regular.ttf');
  }

  @font-face {
    font-family: 'Josefin Sans';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}JosefinSans-Italic.ttf');
  }

  @font-face {
    font-family: 'Josefin Sans';
    font-weight: 600;
    src: url('${publicFontPath}JosefinSans-SemiBold.ttf');
  }

  @font-face {
    font-family: 'Josefin Sans';
    font-weight: 600;
    font-style: italic;
    src: url('${publicFontPath}JosefinSans-SemiBoldItalic.ttf');
  }

  @font-face {
    font-family: 'Josefin Sans';
    font-weight: 700;
    src: url('${publicFontPath}JosefinSans-Bold.ttf');
  }

  @font-face {
    font-family: 'Josefin Sans';
    font-weight: 700;
    font-style: italic;
    src: url('${publicFontPath}JosefinSans-BoldItalic.ttf');
  }



  // Josefin Slab
  @font-face {
    font-family: 'Josefin Slab';
    font-weight: 100;
    src: url('${publicFontPath}JosefinSlab-Thin.ttf');
  }

  @font-face {
    font-family: 'Josefin Slab';
    font-weight: 100;
    font-style: italic;
    src: url('${publicFontPath}JosefinSlab-ThinItalic.ttf');
  }

  @font-face {
    font-family: 'Josefin Slab';
    font-weight: 300;
    src: url('${publicFontPath}JosefinSlab-Light.ttf');
  }

  @font-face {
    font-family: 'Josefin Slab';
    font-weight: 300;
    font-style: italic;
    src: url('${publicFontPath}JosefinSlab-LightItalic.ttf');
  }

  @font-face {
    font-family: 'Josefin Slab';
    font-weight: 400;
    src: url('${publicFontPath}JosefinSlab-Regular.ttf');
  }

  @font-face {
    font-family: 'Josefin Slab';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}JosefinSlab-Italic.ttf');
  }

  @font-face {
    font-family: 'Josefin Slab';
    font-weight: 600;
    src: url('${publicFontPath}JosefinSlab-SemiBold.ttf');
  }

  @font-face {
    font-family: 'Josefin Slab';
    font-weight: 600;
    font-style: italic;
    src: url('${publicFontPath}JosefinSlab-SemiBoldItalic.ttf');
  }

  @font-face {
    font-family: 'Josefin Slab';
    font-weight: 700;
    src: url('${publicFontPath}JosefinSlab-Bold.ttf');
  }

  @font-face {
    font-family: 'Josefin Slab';
    font-weight: 700;
    font-style: italic;
    src: url('${publicFontPath}JosefinSlab-BoldItalic.ttf');
  }



  // Karla
  @font-face {
    font-family: 'Karla';
    font-weight: 400;
    src: url('${publicFontPath}Karla-Regular.ttf');
  }

  @font-face {
    font-family: 'Karla';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}Karla-Italic.ttf');
  }

  @font-face {
    font-family: 'Karla';
    font-weight: 700;
    src: url('${publicFontPath}Karla-Bold.ttf');
  }

  @font-face {
    font-family: 'Karla';
    font-weight: 700;
    font-style: italic;
    src: url('${publicFontPath}Karla-BoldItalic.ttf');
  }

  // Katty Lynch
  @font-face {
    font-family: 'Katty Lynch';
    src: url('${publicFontPath}Katty-Lynch.otf') format('opentype'),
      url('${publicFontPath}Katty-Lynch.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Katty Lynch Serif';
    src: url('${publicFontPath}Katty-Lynch-Serif.otf') format('opentype'),
      url('${publicFontPath}Katty-Lynch-Serif.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  // Kristi
  @font-face {
    font-family: 'Kristi';
    font-weight: 400;
    src: url('${publicFontPath}Kristi-Regular.ttf');
  }

  // Libre Baskerville
  @font-face {
    font-family: 'Libre Baskerville';
    font-weight: 400;
    src: url('${publicFontPath}LibreBaskerville-Regular.ttf');
  }

  @font-face {
    font-family: 'Libre Baskerville';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}LibreBaskerville-Italic.ttf');
  }

  @font-face {
    font-family: 'Libre Baskerville';
    font-weight: 700;
    src: url('${publicFontPath}LibreBaskerville-Bold.ttf');
  }



  // Limelight
  @font-face {
    font-family: 'Limelight';
    font-weight: 400;
    src: url('${publicFontPath}Limelight-Regular.ttf');
  }



  // Lora
  @font-face {
    font-family: 'Lora';
    font-weight: 400;
    src: url('${publicFontPath}Lora-Regular.ttf');
  }

  @font-face {
    font-family: 'Lora';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}Lora-Italic.ttf');
  }

  @font-face {
    font-family: 'Lora';
    font-weight: 700;
    src: url('${publicFontPath}Lora-Bold.ttf');
  }

  @font-face {
    font-family: 'Lora';
    font-weight: 700;
    font-style: italic;
    src: url('${publicFontPath}Lora-BoldItalic.ttf');
  }

  // Marcellus
  @font-face {
    font-family: 'Marcellus';
    font-weight: 400;
    src: url('${publicFontPath}Marcellus-Regular.ttf');
  }

  @font-face {
    font-family: 'Marcellus SC';
    font-weight: 400;
    src: url('${publicFontPath}Marcellus-SC-Regular.ttf');
  }

  // Memento Mori
  @font-face {
    font-family: 'Memento Mori';
    font-weight: 400;
    src: url('${publicFontPath}MementoMori-Regular.ttf');
  }

  // Memories
  @font-face {
    font-family: 'Memories';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}Memories.eot');
    src: url('${publicFontPath}Memories.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Memories.woff') format('woff'),
      url('${publicFontPath}Memories.woff2') format('woff2'),
      url('${publicFontPath}Memories.otf') format('opentype'),
      url('${publicFontPath}Memories.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Memories Line';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}Memories-Line.eot');
    src: url('${publicFontPath}Memories-Line.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Memories-Line.woff') format('woff'),
      url('${publicFontPath}Memories-Line.woff2') format('woff2'),
      url('${publicFontPath}Memories-Line.otf') format('opentype'),
      url('${publicFontPath}Memories-Line.ttf') format('truetype');
  }

  // Mereoleona
  @font-face {
    font-family: 'Mereoleona';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}Mereoleona.eot');
    src: url('${publicFontPath}Mereoleona.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Mereoleona.woff') format('woff'),
      url('${publicFontPath}Mereoleona.woff2') format('woff2'),
      url('${publicFontPath}Mereoleona.otf') format('opentype'),
      url('${publicFontPath}Mereoleona.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Mereoleona Script';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}Mereoleona-Script.eot');
    src: url('${publicFontPath}Mereoleona-Script.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Mereoleona-Script.woff') format('woff'),
      url('${publicFontPath}Mereoleona-Script.woff2') format('woff2'),
      url('${publicFontPath}Mereoleona-Script.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Mereoleona Script Alt';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}Mereoleona-Script-Alt.eot');
    src: url('${publicFontPath}Mereoleona-Script-Alt.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Mereoleona-Script-Alt.woff') format('woff'),
      url('${publicFontPath}Mereoleona-Script-Alt.woff2') format('woff2'),
      url('${publicFontPath}Mereoleona-Script-Alt.ttf') format('truetype');
  }

  // Microbrew
  @font-face {
    font-family: 'Microbrew One 3D';
    font-weight: 400;
    src: url('${publicFontPath}Microbrew-One-3D-Regular.otf');
  }

  @font-face {
    font-family: 'Microbrew One Inline';
    font-weight: 400;
    src: url('${publicFontPath}Microbrew-One-Inline-Regular.otf');
  }

  @font-face {
    font-family: 'Microbrew One';
    font-weight: 400;
    src: url('${publicFontPath}Microbrew-One-Regular.otf');
  }

  @font-face {
    font-family: 'Microbrew Two';
    font-weight: 400;
    src: url('${publicFontPath}Microbrew-Two-Regular.otf');
  }

  @font-face {
    font-family: 'Microbrew Two 3D';
    font-weight: 400;
    src: url('${publicFontPath}Microbrew-Two-3D-Regular.otf');
  }

  @font-face {
    font-family: 'Microbrew Three 3D';
    font-weight: 400;
    src: url('${publicFontPath}Microbrew-Three-3D-Regular.otf');
  }
  @font-face {
    font-family: 'Microbrew Three Inline';
    font-weight: 400;
    src: url('${publicFontPath}Microbrew-Three-Inline-Regular.otf');
  }
  @font-face {
    font-family: 'Microbrew Three';
    font-weight: 400;
    src: url('${publicFontPath}Microbrew-Three-Regular.otf');
  }

  // Mindfully
  @font-face {
    font-family: 'Mindfully';
    font-weight: 400;
    src: url('${publicFontPath}Mindfully_Regular.ttf');
  }

  @font-face {
    font-family: 'Mindfully';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}Mindfully_Regular_Italic.ttf');
  }

  @font-face {
    font-family: 'Mindfully Alternate';
    font-weight: 400;
    src: url('${publicFontPath}Mindfully-Alternate.ttf');
  }

  @font-face {
    font-family: 'Mindfully Alternate';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}Mindfully-Alternate-Italic.ttf');
  }

  // Monique
  @font-face {
    font-family: 'Monique';
    font-weight: 400;
    src: url('${publicFontPath}Monique-Italic.ttf');
  }
  @font-face {
    font-family: 'Monique';
    font-weight: 300;
    font-style: italic;
    src: url('${publicFontPath}Monique-LightItalic.ttf');
  }
  @font-face {
    font-family: 'Monique';
    font-weight: 300;
    src: url('${publicFontPath}Monique-Light.ttf');
  }
  @font-face {
    font-family: 'Monique';
    font-weight: 400;
    src: url('${publicFontPath}Monique-Regular.ttf');
  }

  // Mon Voir
  @font-face {
    font-family: 'Mon Voir';
    src: url('${publicFontPath}MonVoir-Regular.woff2') format('woff2'),
        url('${publicFontPath}MonVoir-Regular.woff') format('woff'),
        url('${publicFontPath}MonVoir-Regular.otf') format('opentype'),
        url('${publicFontPath}MonVoir-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  // Monsieur La Doulaise
  @font-face {
    font-family: 'Monsieur La Doulaise';
    font-weight: 400;
    src: url('${publicFontPath}MonsieurLaDoulaise-Regular.ttf');
  }

  // Montserrat
  @font-face {
    font-family: 'Montserrat';
    font-weight: 100;
    src: url('${publicFontPath}Montserrat-Thin.ttf');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 100;
    font-style: italic;
    src: url('${publicFontPath}Montserrat-ThinItalic.ttf');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 200;
    src: url('${publicFontPath}Montserrat-ExtraLight.ttf');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 200;
    font-style: italic;
    src: url('${publicFontPath}Montserrat-ExtraLightItalic.ttf');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 300;
    src: url('${publicFontPath}Montserrat-Light.ttf');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 300;
    font-style: italic;
    src: url('${publicFontPath}Montserrat-LightItalic.ttf');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 400;
    src: url('${publicFontPath}Montserrat-Regular.ttf');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}Montserrat-Italic.ttf');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 500;
    src: url('${publicFontPath}Montserrat-Medium.ttf');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 500;
    font-style: italic;
    src: url('${publicFontPath}Montserrat-MediumItalic.ttf');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 600;
    src: url('${publicFontPath}Montserrat-SemiBold.ttf');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 600;
    font-style: italic;
    src: url('${publicFontPath}Montserrat-SemiBoldItalic.ttf');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 700;
    src: url('${publicFontPath}Montserrat-Bold.ttf');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 700;
    font-style: italic;
    src: url('${publicFontPath}Montserrat-BoldItalic.ttf');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 800;
    src: url('${publicFontPath}Montserrat-ExtraBold.ttf');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 800;
    font-style: italic;
    src: url('${publicFontPath}Montserrat-ExtraBoldItalic.ttf');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 900;
    src: url('${publicFontPath}Montserrat-Black.ttf');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 900;
    font-style: italic;
    src: url('${publicFontPath}Montserrat-BlackItalic.ttf');
  }

  // Mozart Script CutOff
  @font-face {
    font-family: 'Mozart Script CutOff';
    font-weight: 900;
    src: url('${publicFontPath}MozartScriptCutOff-Black.ttf');
  }
  @font-face {
    font-family: 'Mozart Script CutOff';
    font-weight: 700;
    src: url('${publicFontPath}MozartScriptCutOff-Bold.ttf');
  }
  @font-face {
    font-family: 'Mozart Script CutOff';
    font-weight: 400;
    src: url('${publicFontPath}MozartScriptCutOff-Regular.ttf');
  }
  @font-face {
    font-family: 'Mozart Script CutOff';
    font-weight: 100;
    src: url('${publicFontPath}MozartScriptCutOff-Thin.ttf');
  }

  // Mozart Script
  @font-face {
    font-family: 'Mozart Script';
    font-weight: 900;
    src: url('${publicFontPath}MozartScript-Black.otf');
  }
  @font-face {
    font-family: 'Mozart Script';
    font-weight: 700;
    src: url('${publicFontPath}MozartScript-Bold.otf');
  }
  @font-face {
    font-family: 'Mozart Script';
    font-weight: 400;
    src: url('${publicFontPath}MozartScript-Regular.otf');
  }
  @font-face {
    font-family: 'Mozart Script';
    font-weight: 100;
    src: url('${publicFontPath}MozartScript-Thin.otf');
  }

  // Mr Christo
  @font-face {
    font-family: 'Mr Christo';
    font-weight: 400;
    src: url('${publicFontPath}MrChristo-Regular.otf');
  }

  // Mrs Eaves Small Caps
  @font-face {
    font-family: 'Mrs Eaves Roman Small Caps';
    font-weight: 400;
    font-variant: small-caps;
    src: url('${publicFontPath}MrsEaves-SmallCaps-Regular.otf');
  }

  // Mrs Eaves All Small Caps
  @font-face {
    font-family: 'Mrs Eaves Roman All Small Caps';
    font-weight: 400;
    font-variant: all-small-caps;
    src: url('${publicFontPath}MrsEaves-AllSmallCaps-Regular.otf');
  }

  // Mrs Eaves
  @font-face {
    font-family: 'Mrs Eaves Italic';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}MrsEaves-Italic-Regular.ttf');
  }

  // Ms Jollie
  @font-face {
    font-family: 'Ms Jollie';
    src: url('${publicFontPath}Ms-Jollie-Regular.woff2') format('woff2'),
        url('${publicFontPath}Ms-Jollie-Regular.woff') format('woff'),
        url('${publicFontPath}Ms-Jollie-Regular.otf') format('opentype'),
        url('${publicFontPath}Ms-Jollie-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  // Muli
  @font-face {
    font-family: 'Muli';
    font-weight: 200;
    src: url('${publicFontPath}Muli-ExtraLight.ttf');
  }

  @font-face {
    font-family: 'Muli';
    font-weight: 200;
    font-style: italic;
    src: url('${publicFontPath}Muli-ExtraLightItalic.ttf');
  }

  @font-face {
    font-family: 'Muli';
    font-weight: 300;
    src: url('${publicFontPath}Muli-Light.ttf');
  }

  @font-face {
    font-family: 'Muli';
    font-weight: 300;
    font-style: italic;
    src: url('${publicFontPath}Muli-LightItalic.ttf');
  }

  @font-face {
    font-family: 'Muli';
    font-weight: 400;
    src: url('${publicFontPath}Muli-Regular.ttf');
  }

  @font-face {
    font-family: 'Muli';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}Muli-Italic.ttf');
  }

  @font-face {
    font-family: 'Muli';
    font-weight: 600;
    src: url('${publicFontPath}Muli-SemiBold.ttf');
  }

  @font-face {
    font-family: 'Muli';
    font-weight: 600;
    font-style: italic;
    src: url('${publicFontPath}Muli-SemiBoldItalic.ttf');
  }

  @font-face {
    font-family: 'Muli';
    font-weight: 700;
    src: url('${publicFontPath}Muli-Bold.ttf');
  }

  @font-face {
    font-family: 'Muli';
    font-weight: 700;
    font-style: italic;
    src: url('${publicFontPath}Muli-BoldItalic.ttf');
  }

  @font-face {
    font-family: 'Muli';
    font-weight: 800;
    src: url('${publicFontPath}Muli-ExtraBold.ttf');
  }

  @font-face {
    font-family: 'Muli';
    font-weight: 800;
    font-style: italic;
    src: url('${publicFontPath}Muli-ExtraBoldItalic.ttf');
  }

  @font-face {
    font-family: 'Muli';
    font-weight: 900;
    src: url('${publicFontPath}Muli-Black.ttf');
  }

  @font-face {
    font-family: 'Muli';
    font-weight: 900;
    font-style: italic;
    src: url('${publicFontPath}Muli-BlackItalic.ttf');
  }



  // Nanum Myeongjo
  @font-face {
    font-family: 'Nanum Myeongjo';
    font-weight: 400;
    src: url('${publicFontPath}NanumMyeongjo-Regular.ttf');
  }

  @font-face {
    font-family: 'Nanum Myeongjo';
    font-weight: 700;
    src: url('${publicFontPath}NanumMyeongjo-Bold.ttf');
  }

  @font-face {
    font-family: 'Nanum Myeongjo';
    font-weight: 800;
    src: url('${publicFontPath}NanumMyeongjo-ExtraBold.ttf');
  }

  // Noirden
  @font-face {
    font-family: 'Noirden Bold';
    src: url('${publicFontPath}Noirden-Bold.eot');
    src: url('${publicFontPath}Noirden-Bold.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Noirden-Bold.woff2') format('woff2'),
      url('${publicFontPath}Noirden-Bold.woff') format('woff'),
      url('${publicFontPath}Noirden-Bold.otf') format('opentype'),
      url('${publicFontPath}Noirden-Bold.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Noirden Bold oblique';
    src: url('${publicFontPath}Noirden-Bold-oblique.eot');
    src: url('${publicFontPath}Noirden-Bold-oblique.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Noirden-Bold-oblique.woff2') format('woff2'),
      url('${publicFontPath}Noirden-Bold-oblique.woff') format('woff'),
      url('${publicFontPath}Noirden-Bold-oblique.otf') format('opentype'),
      url('${publicFontPath}Noirden-Bold-oblique.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Noirden Extra Light';
    src: url('${publicFontPath}Noirden-Extra-Light.eot');
    src: url('${publicFontPath}Noirden-Extra-Light.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Noirden-Extra-Light.woff2') format('woff2'),
      url('${publicFontPath}Noirden-Extra-Light.woff') format('woff'),
      url('${publicFontPath}Noirden-Extra-Light.otf') format('opentype'),
      url('${publicFontPath}Noirden-Extra-Light.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Noirden Extra Light Oblique';
    src: url('${publicFontPath}Noirden-Extra-Light-oblique.eot');
    src: url('${publicFontPath}Noirden-Extra-Light-oblique.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Noirden-Extra-Light-oblique.woff2') format('woff2'),
      url('${publicFontPath}Noirden-Extra-Light-oblique.woff') format('woff'),
      url('${publicFontPath}Noirden-Extra-Light-oblique.otf') format('opentype'),
      url('${publicFontPath}Noirden-Extra-Light-oblique.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Noirden Light';
    src: url('${publicFontPath}Noirden-Light.eot');
    src: url('${publicFontPath}Noirden-Light.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Noirden-Light.woff2') format('woff2'),
      url('${publicFontPath}Noirden-Light.woff') format('woff'),
      url('${publicFontPath}Noirden-Light.otf') format('opentype'),
      url('${publicFontPath}Noirden-Light.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Noirden Light Oblique';
    src: url('${publicFontPath}Noirden-Light-oblique.eot');
    src: url('${publicFontPath}Noirden-Light-oblique.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Noirden-Light-oblique.woff2') format('woff2'),
      url('${publicFontPath}Noirden-Light-oblique.woff') format('woff'),
      url('${publicFontPath}Noirden-Light-oblique.otf') format('opentype'),
      url('${publicFontPath}Noirden-Light-oblique.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Noirden Regular';
    src: url('${publicFontPath}Noirden-Regular.eot');
    src: url('${publicFontPath}Noirden-Regular.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Noirden-Regular.woff2') format('woff2'),
      url('${publicFontPath}Noirden-Regular.woff') format('woff'),
      url('${publicFontPath}Noirden-Regular.otf') format('opentype'),
      url('${publicFontPath}Noirden-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Noirden Regular Oblique';
    src: url('${publicFontPath}Noirden-Regular-oblique.eot');
    src: url('${publicFontPath}Noirden-Regular-oblique.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Noirden-Regular-oblique.woff2') format('woff2'),
      url('${publicFontPath}Noirden-Regular-oblique.woff') format('woff'),
      url('${publicFontPath}Noirden-Regular-oblique.otf') format('opentype'),
      url('${publicFontPath}Noirden-Regular-oblique.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Noirden Semi Bold Oblique';
    src: url('${publicFontPath}Noirden-SemiBold-oblique.eot');
    src: url('${publicFontPath}Noirden-SemiBold-oblique.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Noirden-SemiBold-oblique.woff2') format('woff2'),
      url('${publicFontPath}Noirden-SemiBold-oblique.woff') format('woff'),
      url('${publicFontPath}Noirden-SemiBold-oblique.otf') format('opentype'),
      url('${publicFontPath}Noirden-SemiBold-oblique.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Noirden Semi Bold';
    src: url('${publicFontPath}Noirden-SemiBold.eot');
    src: url('${publicFontPath}Noirden-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Noirden-SemiBold.woff2') format('woff2'),
      url('${publicFontPath}Noirden-SemiBold.woff') format('woff'),
      url('${publicFontPath}Noirden-SemiBold.otf') format('opentype'),
      url('${publicFontPath}Noirden-SemiBold.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Noirden Thin Oblique';
    src: url('${publicFontPath}Noirden-Thin-oblique.eot');
    src: url('${publicFontPath}Noirden-Thin-oblique.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Noirden-Thin-oblique.woff2') format('woff2'),
      url('${publicFontPath}Noirden-Thin-oblique.woff') format('woff'),
      url('${publicFontPath}Noirden-Thin-oblique.otf') format('opentype'),
      url('${publicFontPath}Noirden-Thin-oblique.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Noirden Thin';
    src: url('${publicFontPath}Noirden-Thin.eot');
    src: url('${publicFontPath}Noirden-Thin.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Noirden-Thin.woff2') format('woff2'),
      url('${publicFontPath}Noirden-Thin.woff') format('woff'),
      url('${publicFontPath}Noirden-Thin.otf') format('opentype'),
      url('${publicFontPath}Noirden-Thin.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  // Norfolk
  @font-face {
    font-family: 'Norfolk Narrow';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}Norfolk-Narrow.eot');
    src: url('${publicFontPath}Norfolk-Narrow.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Norfolk-Narrow.woff') format('woff'),
      url('${publicFontPath}Norfolk-Narrow.woff2') format('woff2'),
      url('${publicFontPath}Norfolk-Narrow.otf') format('opentype'),
      url('${publicFontPath}Norfolk-Narrow.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Norfolk Serif';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}Norfolk-Serif.eot');
    src: url('${publicFontPath}Norfolk-Serif.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Norfolk-Serif.woff') format('woff'),
      url('${publicFontPath}Norfolk-Serif.woff2') format('woff2'),
      url('${publicFontPath}Norfolk-Serif.otf') format('opentype'),
      url('${publicFontPath}Norfolk-Serif.ttf') format('truetype');
  }

  // Nunito Sans
  @font-face {
    font-family: 'Nunito Sans ExtraLight';
    font-weight: 200;
    font-style: normal;
    src: url('${publicFontPath}NunitoSans-ExtraLight.ttf');
    src: url('${publicFontPath}NunitoSans-ExtraLight.eot') format('embedded-opentype'),
      url('${publicFontPath}NunitoSans-ExtraLight.woff2') format('woff2'),
      url('${publicFontPath}NunitoSans-ExtraLight.woff') format('woff');
  }

  @font-face {
    font-family: 'Nunito Sans ExtraLight';
    font-weight: 200;
    font-style: italic;
    src: url('${publicFontPath}NunitoSans-ExtraLightItalic.ttf');
    src: url('${publicFontPath}NunitoSans-ExtraLightItalic.eot') format('embedded-opentype'),
      url('${publicFontPath}NunitoSans-ExtraLightItalic.woff2') format('woff2'),
      url('${publicFontPath}NunitoSans-ExtraLightItalic.woff') format('woff');
  }

  @font-face {
    font-family: 'Nunito Sans Light';
    font-weight: 300;
    font-style: normal;
    src: url('${publicFontPath}NunitoSans-Light.ttf');
    src: url('${publicFontPath}NunitoSans-Light.eot') format('embedded-opentype'),
      url('${publicFontPath}NunitoSans-Light.woff2') format('woff2'),
      url('${publicFontPath}NunitoSans-Light.woff') format('woff');
  }

  @font-face {
    font-family: 'Nunito Sans Light';
    font-weight: 300;
    font-style: italic;
    src: url('${publicFontPath}NunitoSans-LightItalic.ttf');
    src: url('${publicFontPath}NunitoSans-LightItalic.eot') format('embedded-opentype'),
      url('${publicFontPath}NunitoSans-LightItalic.woff2') format('woff2'),
      url('${publicFontPath}NunitoSans-LightItalic.woff') format('woff');
  }

  @font-face {
    font-family: 'Nunito Sans';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}NunitoSans-Regular.ttf');
    src: url('${publicFontPath}NunitoSans-Regular.eot') format('embedded-opentype'),
      url('${publicFontPath}NunitoSans-Regular.woff2') format('woff2'),
      url('${publicFontPath}NunitoSans-Regular.woff') format('woff');
  }

  @font-face {
    font-family: 'Nunito Sans';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}NunitoSans-Italic.ttf');
    src: url('${publicFontPath}NunitoSans-Italic.eot') format('embedded-opentype'),
      url('${publicFontPath}NunitoSans-Italic.woff2') format('woff2'),
      url('${publicFontPath}NunitoSans-Italic.woff') format('woff');
  }

  @font-face {
    font-family: 'Nunito Sans SemiBold';
    font-weight: 600;
    font-style: normal;
    src: url('${publicFontPath}NunitoSans-SemiBold.ttf');
    src: url('${publicFontPath}NunitoSans-SemiBold.eot') format('embedded-opentype'),
      url('${publicFontPath}NunitoSans-SemiBold.woff2') format('woff2'),
      url('${publicFontPath}NunitoSans-SemiBold.woff') format('woff');
  }

  @font-face {
    font-family: 'Nunito Sans SemiBold';
    font-weight: 600;
    font-style: italic;
    src: url('${publicFontPath}NunitoSans-SemiBoldItalic.ttf');
    src: url('${publicFontPath}NunitoSans-SemiBoldItalic.eot') format('embedded-opentype'),
      url('${publicFontPath}NunitoSans-SemiBoldItalic.woff2') format('woff2'),
      url('${publicFontPath}NunitoSans-SemiBoldItalic.woff') format('woff');
  }

  @font-face {
    font-family: 'Nunito Sans';
    font-weight: 700;
    font-style: normal;
    src: url('${publicFontPath}NunitoSans-Bold.ttf');
    src: url('${publicFontPath}NunitoSans-Bold.eot') format('embedded-opentype'),
      url('${publicFontPath}NunitoSans-Bold.woff2') format('woff2'),
      url('${publicFontPath}NunitoSans-Bold.woff') format('woff');
  }

  @font-face {
    font-family: 'Nunito Sans';
    font-weight: 700;
    font-style: italic;
    src: url('${publicFontPath}NunitoSans-BoldItalic.ttf');
    src: url('${publicFontPath}NunitoSans-BoldItalic.eot') format('embedded-opentype'),
      url('${publicFontPath}NunitoSans-BoldItalic.woff2') format('woff2'),
      url('${publicFontPath}NunitoSans-BoldItalic.woff') format('woff');
  }

  @font-face {
    font-family: 'Nunito Sans ExtraBold';
    font-weight: 800;
    font-style: normal;
    src: url('${publicFontPath}NunitoSans-ExtraBold.ttf');
    src: url('${publicFontPath}NunitoSans-ExtraBold.eot') format('embedded-opentype'),
      url('${publicFontPath}NunitoSans-ExtraBold.woff2') format('woff2'),
      url('${publicFontPath}NunitoSans-ExtraBold.woff') format('woff');
  }

  @font-face {
    font-family: 'Nunito Sans ExtraBold';
    font-weight: 800;
    font-style: italic;
    src: url('${publicFontPath}NunitoSans-ExtraBoldItalic.ttf');
    src: url('${publicFontPath}NunitoSans-ExtraBoldItalic.eot') format('embedded-opentype'),
      url('${publicFontPath}NunitoSans-ExtraBoldItalic.woff2') format('woff2'),
      url('${publicFontPath}NunitoSans-ExtraBoldItalic.woff') format('woff');
  }

  @font-face {
    font-family: 'Nunito Sans Black';
    font-weight: 900;
    font-style: normal;
    src: url('${publicFontPath}NunitoSans-Black.ttf');
    src: url('${publicFontPath}NunitoSans-Black.eot') format('embedded-opentype'),
      url('${publicFontPath}NunitoSans-Black.woff2') format('woff2'),
      url('${publicFontPath}NunitoSans-Black.woff') format('woff');
  }

  @font-face {
    font-family: 'Nunito Sans Black';
    font-weight: 900;
    font-style: italic;
    src: url('${publicFontPath}NunitoSans-BlackItalic.ttf');
    src: url('${publicFontPath}NunitoSans-BlackItalic.eot') format('embedded-opentype'),
      url('${publicFontPath}NunitoSans-BlackItalic.woff2') format('woff2'),
      url('${publicFontPath}NunitoSans-BlackItalic.woff') format('woff');
  }

  // Old Standard TT
  @font-face {
    font-family: 'Old Standard TT';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}OldStandard-Italic.ttf');
  }

  @font-face {
    font-family: 'Old Standard TT';
    font-weight: 700;
    font-style: normal;
    src: url('${publicFontPath}OldStandard-Bold.ttf');
  }

  @font-face {
    font-family: 'Old Standard TT';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}OldStandard-Regular.ttf');
  }

  // Olivia Serif
  @font-face {
    font-family: 'Oliva Serif Font';
    src: url('${publicFontPath}Oliva-Serif-Font.eot');
    src: url('${publicFontPath}Oliva-Serif-Font.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Oliva-Serif-Font.woff2') format('woff2'),
      url('${publicFontPath}Oliva-Serif-Font.woff') format('woff'),
      url('${publicFontPath}Oliva-Serif-Font.svg') format('svg'),
      url('${publicFontPath}Oliva-Serif-Font.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  // Open Sans
  @font-face {
    font-family: 'Open Sans';
    font-weight: 300;
    src: url('${publicFontPath}OpenSans-Light.ttf');
  }
  @font-face {
    font-family: 'Open Sans';
    font-weight: 300;
    font-style: italic;
    src: url('${publicFontPath}OpenSans-LightItalic.ttf');
  }
  @font-face {
    font-family: 'Open Sans';
    font-weight: 400;
    src: url('${publicFontPath}OpenSans-Regular.ttf');
  }
  @font-face {
    font-family: 'Open Sans';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}OpenSans-Italic.ttf');
  }
  @font-face {
    font-family: 'Open Sans';
    font-weight: 600;
    src: url('${publicFontPath}OpenSans-SemiBold.ttf');
  }
  @font-face {
    font-family: 'Open Sans';
    font-weight: 600;
    font-style: italic;
    src: url('${publicFontPath}OpenSans-SemiBoldItalic.ttf');
  }
  @font-face {
    font-family: 'Open Sans';
    font-weight: 700;
    src: url('${publicFontPath}OpenSans-Bold.ttf');
  }
  @font-face {
    font-family: 'Open Sans';
    font-weight: 700;
    font-style: italic;
    src: url('${publicFontPath}OpenSans-BoldItalic.ttf');
  }
  @font-face {
    font-family: 'Open Sans';
    font-weight: 800;
    src: url('${publicFontPath}OpenSans-ExtraBold.ttf');
  }
  @font-face {
    font-family: 'Open Sans';
    font-weight: 800;
    font-style: italic;
    src: url('${publicFontPath}OpenSans-ExtraBoldItalic.ttf');
  }

  // Optima LT Pro Bold
  @font-face {
    font-family: 'Optima LT Pro';
    font-weight: 700;
    src: url('${publicFontPath}OptimaLTPro-Bold.otf');
  }

  // Optima LT Pro Regular
  @font-face {
    font-family: 'Optima LT Pro';
    font-weight: 400;
    src: url('${publicFontPath}OptimaLTPro-Regular.otf');
  }


  // Parisienne
  @font-face {
    font-family: 'Parisienne';
    font-weight: 400;
    src: url('${publicFontPath}Parisienne-Regular.ttf');
  }



  // Petit Formal Script
  @font-face {
    font-family: 'Petit Formal Script';
    font-weight: 400;
    src: url('${publicFontPath}PetitFormalScript-Regular.ttf');
  }



  // Pinyon Script
  @font-face {
    font-family: 'Pinyon Script';
    font-weight: 400;
    src: url('${publicFontPath}PinyonScript-Regular.ttf');
  }



  // Playfair Display
  @font-face {
    font-family: 'Playfair Display';
    font-weight: 400;
    src: url('${publicFontPath}PlayfairDisplay-Regular.ttf');
  }

  @font-face {
    font-family: 'Playfair Display';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}PlayfairDisplay-Italic.ttf');
  }

  @font-face {
    font-family: 'Playfair Display';
    font-weight: 700;
    src: url('${publicFontPath}PlayfairDisplay-Bold.ttf');
  }

  @font-face {
    font-family: 'Playfair Display';
    font-weight: 700;
    font-style: italic;
    src: url('${publicFontPath}PlayfairDisplay-BoldItalic.ttf');
  }

  @font-face {
    font-family: 'Playfair Display';
    font-weight: 900;
    src: url('${publicFontPath}PlayfairDisplay-Black.ttf');
  }

  @font-face {
    font-family: 'Playfair Display';
    font-weight: 900;
    font-style: italic;
    src: url('${publicFontPath}PlayfairDisplay-BlackItalic.ttf');
  }

  // Poiret One
  @font-face {
    font-family: 'Poiret One';
    font-weight: 400;
    src: url('${publicFontPath}PoiretOne-Regular.ttf');
  }

  // Playball
  @font-face {
    font-family: 'Playball';
    font-weight: 400;
    src: url('${publicFontPath}Playball-Regular.ttf');
  }

  // Poppins
  @font-face {
    font-family: 'Poppins';
    font-weight: 100;
    src: url('${publicFontPath}Poppins-Thin.ttf');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 100;
    font-style: italic;
    src: url('${publicFontPath}Poppins-ThinItalic.ttf');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 200;
    src: url('${publicFontPath}Poppins-ExtraLight.ttf');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 200;
    font-style: italic;
    src: url('${publicFontPath}Poppins-ExtraLightItalic.ttf');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 300;
    src: url('${publicFontPath}Poppins-Light.ttf');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 300;
    font-style: italic;
    src: url('${publicFontPath}Poppins-LightItalic.ttf');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 400;
    src: url('${publicFontPath}Poppins-Regular.ttf');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}Poppins-Italic.ttf');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 500;
    src: url('${publicFontPath}Poppins-Medium.ttf');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 500;
    font-style: italic;
    src: url('${publicFontPath}Poppins-MediumItalic.ttf');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 600;
    src: url('${publicFontPath}Poppins-SemiBold.ttf');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 600;
    font-style: italic;
    src: url('${publicFontPath}Poppins-SemiBoldItalic.ttf');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 700;
    src: url('${publicFontPath}Poppins-Bold.ttf');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 700;
    font-style: italic;
    src: url('${publicFontPath}Poppins-BoldItalic.ttf');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 800;
    src: url('${publicFontPath}Poppins-ExtraBold.ttf');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 800;
    font-style: italic;
    src: url('${publicFontPath}Poppins-ExtraBoldItalic.ttf');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 900;
    src: url('${publicFontPath}Poppins-Black.ttf');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 900;
    font-style: italic;
    src: url('${publicFontPath}Poppins-BlackItalic.ttf');
  }

  // Potregi
  @font-face {
    font-family: 'Potregi';
    src: url('${publicFontPath}Potregi.eot');
    src: url('${publicFontPath}Potregi.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Potregi.woff2') format('woff2'),
      url('${publicFontPath}Potregi.woff') format('woff'),
      url('${publicFontPath}Potregi.otf') format('opentype'),
      url('${publicFontPath}Potregi.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  // Prata
  @font-face {
    font-family: 'Prata';
    font-weight: 400;
    src: url('${publicFontPath}Prata-Regular.ttf');
  }

  // * Proxima Nova
  // * Do NOT DELETE as it's used in the customization flow
  // * The font family name matters here (used in the font dropdown - user facing)
  @font-face {
    font-family: 'Proxima Nova';
    font-weight: 300;
    src: url('${publicFontPath}ProximaNova-Light.otf'),
        url('${publicFontPath}ProximaNova-Light.eot') format('embedded-opentype'),
        url('${publicFontPath}ProximaNova-Light.woff2') format('woff2'),
        url('${publicFontPath}ProximaNova-Light.woff') format('woff'),
        url('${publicFontPath}ProximaNova-Light.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Proxima Nova';
    font-weight: 300;
    font-style: italic;
    src: url('${publicFontPath}ProximaNova-LightItalic.otf'),
        url('${publicFontPath}ProximaNova-LightItalic.eot') format('embedded-opentype'),
        url('${publicFontPath}ProximaNova-LightItalic.woff2') format('woff2'),
        url('${publicFontPath}ProximaNova-LightItalic.woff') format('woff'),
        url('${publicFontPath}ProximaNova-LightItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Proxima Nova';
    font-weight: 400;
    src: url('${publicFontPath}ProximaNova-Regular.otf'),
        url('${publicFontPath}ProximaNova-Regular.eot') format('embedded-opentype'),
        url('${publicFontPath}ProximaNova-Regular.woff2') format('woff2'),
        url('${publicFontPath}ProximaNova-Regular.woff') format('woff'),
        url('${publicFontPath}ProximaNova-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Proxima Nova';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}ProximaNova-RegularItalic.otf'),
        url('${publicFontPath}ProximaNova-RegularItalic.eot') format('embedded-opentype'),
        url('${publicFontPath}ProximaNova-RegularItalic.woff2') format('woff2'),
        url('${publicFontPath}ProximaNova-RegularItalic.woff') format('woff'),
        url('${publicFontPath}ProximaNova-RegularItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Proxima Nova';
    font-weight: 600;
    src: url('${publicFontPath}ProximaNova-Semibold.otf'),
        url('${publicFontPath}ProximaNova-Semibold.eot') format('embedded-opentype'),
        url('${publicFontPath}ProximaNova-Semibold.woff2') format('woff2'),
        url('${publicFontPath}ProximaNova-Semibold.woff') format('woff'),
        url('${publicFontPath}ProximaNova-Semibold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Proxima Nova';
    font-weight: 600;
    font-style: italic;
    src: url('${publicFontPath}ProximaNova-SemiboldItalic.otf'),
        url('${publicFontPath}ProximaNova-SemiboldItalic.eot') format('embedded-opentype'),
        url('${publicFontPath}ProximaNova-SemiboldItalic.woff2') format('woff2'),
        url('${publicFontPath}ProximaNova-SemiboldItalic.woff') format('woff'),
        url('${publicFontPath}ProximaNova-SemiboldItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Proxima Nova';
    font-weight: 700;
    src: url('${publicFontPath}ProximaNova-Bold.otf'),
        url('${publicFontPath}ProximaNova-Bold.eot') format('embedded-opentype'),
        url('${publicFontPath}ProximaNova-Bold.woff2') format('woff2'),
        url('${publicFontPath}ProximaNova-Bold.woff') format('woff'),
        url('${publicFontPath}ProximaNova-Bold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Proxima Nova';
    font-weight: 700;
    font-style: italic;
    src: url('${publicFontPath}ProximaNova-BoldIt.otf'),
        url('${publicFontPath}ProximaNova-BoldIt.eot') format('embedded-opentype'),
        url('${publicFontPath}ProximaNova-BoldIt.woff2') format('woff2'),
        url('${publicFontPath}ProximaNova-BoldIt.woff') format('woff'),
        url('${publicFontPath}ProximaNova-BoldIt.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Proxima Nova';
    font-weight: 800;
    src: url('${publicFontPath}ProximaNova-Extrabold.otf'),
        url('${publicFontPath}ProximaNova-Extrabold.eot') format('embedded-opentype'),
        url('${publicFontPath}ProximaNova-Extrabold.woff2') format('woff2'),
        url('${publicFontPath}ProximaNova-Extrabold.woff') format('woff'),
        url('${publicFontPath}ProximaNova-Extrabold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Proxima Nova';
    font-weight: 900;
    src: url('${publicFontPath}ProximaNova-Black.otf'),
        url('${publicFontPath}ProximaNova-Black.eot') format('embedded-opentype'),
        url('${publicFontPath}ProximaNova-Black.woff2') format('woff2'),
        url('${publicFontPath}ProximaNova-Black.woff') format('woff'),
        url('${publicFontPath}ProximaNova-Black.ttf') format('truetype');
  }


  // Quarzo Regular
  @font-face {
    font-family: 'Quarzo';
    font-weight: 400;
    src: url('${publicFontPath}Quarzo-Regular.otf');
  }

  // Quattrocento
  @font-face {
    font-family: 'Quattrocento';
    font-weight: 400;
    src: url('${publicFontPath}Quattrocento-Regular.ttf');
  }

  @font-face {
    font-family: 'Quattrocento';
    font-weight: 700;
    src: url('${publicFontPath}Quattrocento-Bold.ttf');
  }

  // Quattrocento Sans

  @font-face {
    font-family: 'Quattrocento Sans';
    font-weight: 400;
    src: url('${publicFontPath}QuattrocentoSans-Regular.ttf');
  }

  @font-face {
    font-family: 'Quattrocento Sans';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}QuattrocentoSans-Italic.ttf');
  }

  @font-face {
    font-family: 'Quattrocento Sans';
    font-weight: 700;
    src: url('${publicFontPath}QuattrocentoSans-Bold.ttf');
  }

  @font-face {
    font-family: 'Quattrocento Sans';
    font-weight: 700;
    font-style: italic;
    src: url('${publicFontPath}QuattrocentoSans-BoldItalic.ttf');
  }

  // Questrial
  @font-face {
    font-family: 'Questrial';
    font-weight: 400;
    src: url('${publicFontPath}Questrial-Regular.ttf');
  }

  // Quicksand
  @font-face {
    font-family: 'Quicksand';
    font-weight: 300;
    src: url('${publicFontPath}Quicksand-Light.ttf');
  }

  @font-face {
    font-family: 'Quicksand';
    font-weight: 400;
    src: url('${publicFontPath}Quicksand-Regular.ttf');
  }

  @font-face {
    font-family: 'Quicksand';
    font-weight: 500;
    src: url('${publicFontPath}Quicksand-Medium.ttf');
  }

  @font-face {
    font-family: 'Quicksand';
    font-weight: 700;
    src: url('${publicFontPath}Quicksand-Bold.ttf');
  }

  // Quiche Flare
  @font-face {
    font-family: 'Quiche Flare';
    font-weight: 500;
    font-style: italic;
    src: url('${publicFontPath}Quiche-Flare-Medium-Italic.eot');
    src: url('${publicFontPath}Quiche-Flare-Medium-Italic.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Quiche-Flare-Medium-Italic.woff') format('woff'),
      url('${publicFontPath}Quiche-Flare-Medium-Italic.woff2') format('woff2'),
      url('${publicFontPath}Quiche-Flare-Medium-Italic.otf') format('opentype'),
      url('${publicFontPath}Quiche-Flare-Medium-Italic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Quiche Flare';
    font-weight: 100;
    font-style: italic;
    src: url('${publicFontPath}Quiche-Flare-Thin-Italic.eot');
    src: url('${publicFontPath}Quiche-Flare-Thin-Italic.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Quiche-Flare-Thin-Italic.woff') format('woff'),
      url('${publicFontPath}Quiche-Flare-Thin-Italic.woff2') format('woff2'),
      url('${publicFontPath}Quiche-Flare-Thin-Italic.otf') format('opentype'),
      url('${publicFontPath}Quiche-Flare-Thin-Italic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Quiche Flare';
    font-weight: 800;
    font-style: italic;
    src: url('${publicFontPath}Quiche-Flare-ExtraBold-Italic.eot');
    src: url('${publicFontPath}Quiche-Flare-ExtraBold-Italic.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Quiche-Flare-ExtraBold-Italic.woff') format('woff'),
      url('${publicFontPath}Quiche-Flare-ExtraBold-Italic.woff2') format('woff2'),
      url('${publicFontPath}Quiche-Flare-ExtraBold-Italic.otf') format('opentype'),
      url('${publicFontPath}Quiche-Flare-ExtraBold-Italic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Quiche Flare';
    font-weight: 700;
    font-style: italic;
    src: url('${publicFontPath}Quiche-Flare-Bold-Italic.eot');
    src: url('${publicFontPath}Quiche-Flare-Bold-Italic.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Quiche-Flare-Bold-Italic.woff') format('woff'),
      url('${publicFontPath}Quiche-Flare-Bold-Italic.woff2') format('woff2'),
      url('${publicFontPath}Quiche-Flare-Bold-Italic.otf') format('opentype'),
      url('${publicFontPath}Quiche-Flare-Bold-Italic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Quiche Flare';
    font-weight: 900;
    font-style: italic;
    src: url('${publicFontPath}Quiche-Flare-Black-Italic.eot');
    src: url('${publicFontPath}Quiche-Flare-Black-Italic.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Quiche-Flare-Black-Italic.woff') format('woff'),
      url('${publicFontPath}Quiche-Flare-Black-Italic.woff2') format('woff2'),
      url('${publicFontPath}Quiche-Flare-Black-Italic.otf') format('opentype'),
      url('${publicFontPath}Quiche-Flare-Black-Italic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Quiche Flare';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}Quiche-Flare-Italic.eot');
    src: url('${publicFontPath}Quiche-Flare-Italic.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Quiche-Flare-Italic.woff') format('woff'),
      url('${publicFontPath}Quiche-Flare-Italic.woff2') format('woff2'),
      url('${publicFontPath}Quiche-Flare-Italic.otf') format('opentype'),
      url('${publicFontPath}Quiche-Flare-Italic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Quiche Flare';
    font-weight: 300;
    font-style: italic;
    src: url('${publicFontPath}Quiche-Flare-Light-Italic.eot');
    src: url('${publicFontPath}Quiche-Flare-Light-Italic.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Quiche-Flare-Light-Italic.woff') format('woff'),
      url('${publicFontPath}Quiche-Flare-Light-Italic.woff2') format('woff2'),
      url('${publicFontPath}Quiche-Flare-Light-Italic.otf') format('opentype'),
      url('${publicFontPath}Quiche-Flare-Light-Italic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Quiche Flare';
    font-weight: 500;
    font-style: normal;
    src: url('${publicFontPath}Quiche-Flare-Medium.eot');
    src: url('${publicFontPath}Quiche-Flare-Medium.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Quiche-Flare-Medium.woff') format('woff'),
      url('${publicFontPath}Quiche-Flare-Medium.woff2') format('woff2'),
      url('${publicFontPath}Quiche-Flare-Medium.otf') format('opentype'),
      url('${publicFontPath}Quiche-Flare-Medium.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Quiche Flare';
    font-weight: 100;
    font-style: normal;
    src: url('${publicFontPath}Quiche-Flare-Thin.eot');
    src: url('${publicFontPath}Quiche-Flare-Thin.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Quiche-Flare-Thin.woff') format('woff'),
      url('${publicFontPath}Quiche-Flare-Thin.woff2') format('woff2'),
      url('${publicFontPath}Quiche-Flare-Thin.otf') format('opentype'),
      url('${publicFontPath}Quiche-Flare-Thin.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Quiche Flare';
    font-weight: 300;
    font-style: normal;
    src: url('${publicFontPath}Quiche-Flare-Light.eot');
    src: url('${publicFontPath}Quiche-Flare-Light.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Quiche-Flare-Light.woff') format('woff'),
      url('${publicFontPath}Quiche-Flare-Light.woff2') format('woff2'),
      url('${publicFontPath}Quiche-Flare-Light.otf') format('opentype'),
      url('${publicFontPath}Quiche-Flare-Light.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Quiche Flare';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}Quiche-Flare-Regular.eot');
    src: url('${publicFontPath}Quiche-Flare-Regular.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Quiche-Flare-Regular.woff') format('woff'),
      url('${publicFontPath}Quiche-Flare-Regular.woff2') format('woff2'),
      url('${publicFontPath}Quiche-Flare-Regular.otf') format('opentype'),
      url('${publicFontPath}Quiche-Flare-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Quiche Flare';
    font-weight: 900;
    font-style: normal;
    src: url('${publicFontPath}Quiche-Flare-Black.eot');
    src: url('${publicFontPath}Quiche-Flare-Black.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Quiche-Flare-Black.woff') format('woff'),
      url('${publicFontPath}Quiche-Flare-Black.woff2') format('woff2'),
      url('${publicFontPath}Quiche-Flare-Black.otf') format('opentype'),
      url('${publicFontPath}Quiche-Flare-Black.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Quiche Flare';
    font-weight: 800;
    font-style: normal;
    src: url('${publicFontPath}Quiche-Flare-ExtraBold.eot');
    src: url('${publicFontPath}Quiche-Flare-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Quiche-Flare-ExtraBold.woff') format('woff'),
      url('${publicFontPath}Quiche-Flare-ExtraBold.woff2') format('woff2'),
      url('${publicFontPath}Quiche-Flare-ExtraBold.otf') format('opentype'),
      url('${publicFontPath}Quiche-Flare-ExtraBold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Quiche Flare';
    font-weight: 700;
    font-style: normal;
    src: url('${publicFontPath}Quiche-Flare-Bold.eot');
    src: url('${publicFontPath}Quiche-Flare-Bold.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Quiche-Flare-Bold.woff') format('woff'),
      url('${publicFontPath}Quiche-Flare-Bold.woff2') format('woff2'),
      url('${publicFontPath}Quiche-Flare-Bold.otf') format('opentype'),
      url('${publicFontPath}Quiche-Flare-Bold.ttf') format('truetype');
  }

  // Radiograph
  @font-face {
    font-family: 'Radiograph';
    font-weight: 400;
    src: url('${publicFontPath}Radiograph-Regular.otf');
  }

  // Raleway
  @font-face {
    font-family: 'Raleway';
    font-weight: 100;
    src: url('${publicFontPath}Raleway-Thin.ttf');
  }

  @font-face {
    font-family: 'Raleway';
    font-weight: 100;
    font-style: italic;
    src: url('${publicFontPath}Raleway-ThinItalic.ttf');
  }

  @font-face {
    font-family: 'Raleway';
    font-weight: 200;
    src: url('${publicFontPath}Raleway-ExtraLight.ttf');
  }

  @font-face {
    font-family: 'Raleway';
    font-weight: 200;
    font-style: italic;
    src: url('${publicFontPath}Raleway-ExtraLightItalic.ttf');
  }

  @font-face {
    font-family: 'Raleway';
    font-weight: 300;
    src: url('${publicFontPath}Raleway-Light.ttf');
  }

  @font-face {
    font-family: 'Raleway';
    font-weight: 300;
    font-style: italic;
    src: url('${publicFontPath}Raleway-LightItalic.ttf');
  }

  @font-face {
    font-family: 'Raleway';
    font-weight: 400;
    src: url('${publicFontPath}Raleway-Regular.ttf');
  }

  @font-face {
    font-family: 'Raleway';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}Raleway-Italic.ttf');
  }

  @font-face {
    font-family: 'Raleway';
    font-weight: 500;
    src: url('${publicFontPath}Raleway-Medium.ttf');
  }

  @font-face {
    font-family: 'Raleway';
    font-weight: 500;
    font-style: italic;
    src: url('${publicFontPath}Raleway-MediumItalic.ttf');
  }

  @font-face {
    font-family: 'Raleway';
    font-weight: 600;
    src: url('${publicFontPath}Raleway-SemiBold.ttf');
  }

  @font-face {
    font-family: 'Raleway';
    font-weight: 600;
    font-style: italic;
    src: url('${publicFontPath}Raleway-SemiBoldItalic.ttf');
  }

  @font-face {
    font-family: 'Raleway';
    font-weight: 700;
    src: url('${publicFontPath}Raleway-Bold.ttf');
  }

  @font-face {
    font-family: 'Raleway';
    font-weight: 700;
    font-style: italic;
    src: url('${publicFontPath}Raleway-BoldItalic.ttf');
  }

  @font-face {
    font-family: 'Raleway';
    font-weight: 800;
    src: url('${publicFontPath}Raleway-ExtraBold.ttf');
  }

  @font-face {
    font-family: 'Raleway';
    font-weight: 800;
    font-style: italic;
    src: url('${publicFontPath}Raleway-ExtraBoldItalic.ttf');
  }

  @font-face {
    font-family: 'Raleway';
    font-weight: 900;
    src: url('${publicFontPath}Raleway-Black.ttf');
  }

  @font-face {
    font-family: 'Raleway';
    font-weight: 900;
    font-style: italic;
    src: url('${publicFontPath}Raleway-BlackItalic.ttf');
  }

  // Real One Specific
  @font-face {
    font-family: 'Real one specific';
    src: url('${publicFontPath}Real-One-Specific.eot');
    src: url('${publicFontPath}Real-One-Specific.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Real-One-Specific.woff2') format('woff2'),
      url('${publicFontPath}Real-One-Specific.woff') format('woff'),
      url('${publicFontPath}Real-One-Specific.otf') format('opentype'),
      url('${publicFontPath}Real-One-Specific.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Real one specific script';
    src: url('${publicFontPath}Real-One-Specific-script.eot');
    src: url('${publicFontPath}Real-One-Specific-script.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Real-One-Specific-script.woff2') format('woff2'),
      url('${publicFontPath}Real-One-Specific-script.woff') format('woff'),
      url('${publicFontPath}Real-One-Specific-script.otf') format('opentype'),
      url('${publicFontPath}Real-One-Specific-script.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  // Reylight
  @font-face {
    font-family: 'Reylight';
    src: url('${publicFontPath}Reylight-Regular.woff2') format('woff2'),
        url('${publicFontPath}Reylight-Regular.woff') format('woff'),
        url('${publicFontPath}Reylight-Regular.otf') format('opentype'),
        url('${publicFontPath}Reylight-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  // Roberts Humter
  @font-face {
    font-family: 'Roberts Humter';
    src: url('${publicFontPath}Roberts-Humter-Regular.woff2') format('woff2'),
        url('${publicFontPath}Roberts-Humter-Regular.woff') format('woff'),
        url('${publicFontPath}Roberts-Humter-Regular.otf') format('opentype'),
        url('${publicFontPath}Roberts-Humter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  // Roboto

  @font-face {
    font-family: 'Roboto';
    font-weight: 100;
    src: url('${publicFontPath}Roboto-Thin.ttf');
  }

  @font-face {
    font-family: 'Roboto';
    font-weight: 100;
    font-style: italic;
    src: url('${publicFontPath}Roboto-ThinItalic.ttf');
  }

  @font-face {
    font-family: 'Roboto';
    font-weight: 300;
    src: url('${publicFontPath}Roboto-Light.ttf')
  }

  @font-face {
    font-family: 'Roboto';
    font-weight: 300;
    font-style: italic;
    src: url('${publicFontPath}Roboto-LightItalic.ttf')
  }

  @font-face {
    font-family: 'Roboto';
    font-weight: 400;
    src: url('${publicFontPath}Roboto-Regular.ttf')
  }

  @font-face {
    font-family: 'Roboto';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}Roboto-Italic.ttf')
  }

  @font-face {
    font-family: 'Roboto';
    font-weight: 500;
    src: url('${publicFontPath}Roboto-Medium.ttf')
  }

  @font-face {
    font-family: 'Roboto';
    font-weight: 500;
    font-style: italic;
    src: url('${publicFontPath}Roboto-MediumItalic.ttf')
  }

  @font-face {
    font-family: 'Roboto';
    font-weight: 700;
    src: url('${publicFontPath}Roboto-Bold.ttf');
  }

  @font-face {
    font-family: 'Roboto';
    font-weight: 700;
    font-style: italic;
    src: url('${publicFontPath}Roboto-BoldItalic.ttf');
  }

  @font-face {
    font-family: 'Roboto';
    font-weight: 900;
    src: url('${publicFontPath}Roboto-Black.ttf');
  }

  @font-face {
    font-family: 'Roboto';
    font-weight: 900;
    font-style: italic;
    src: url('${publicFontPath}Roboto-BlackItalic.ttf');
  }

  // Roboto Condensed
  @font-face {
    font-family: 'Roboto Condensed';
    font-weight: 300;
    src: url('${publicFontPath}RobotoCondensed-Light.ttf');
  }

  @font-face {
    font-family: 'Roboto Condensed';
    font-weight: 300;
    font-style: italic;
    src: url('${publicFontPath}RobotoCondensed-LightItalic.ttf');
  }

  @font-face {
    font-family: 'Roboto Condensed';
    font-weight: 400;
    src: url('${publicFontPath}RobotoCondensed-Regular.ttf');
  }

  @font-face {
    font-family: 'Roboto Condensed';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}RobotoCondensed-Italic.ttf');
  }

  @font-face {
    font-family: 'Roboto Condensed';
    font-weight: 700;
    src: url('${publicFontPath}RobotoCondensed-Bold.ttf');
  }

  @font-face {
    font-family: 'Roboto Condensed';
    font-weight: 700;
    font-style: italic;
    src: url('${publicFontPath}RobotoCondensed-BoldItalic.ttf');
  }

  // Roboto Mono
  @font-face {
    font-family: 'Roboto Mono';
    font-weight: 100;
    src: url('${publicFontPath}RobotoMono-Thin.ttf');
  }

  @font-face {
    font-family: 'Roboto Mono';
    font-weight: 100;
    font-style: italic;
    src: url('${publicFontPath}RobotoMono-ThinItalic.ttf');
  }

  @font-face {
    font-family: 'Roboto Mono';
    font-weight: 300;
    src: url('${publicFontPath}RobotoMono-Light.ttf')
  }

  @font-face {
    font-family: 'Roboto Mono';
    font-weight: 300;
    font-style: italic;
    src: url('${publicFontPath}RobotoMono-LightItalic.ttf')
  }

  @font-face {
    font-family: 'Roboto Mono';
    font-weight: 400;
    src: url('${publicFontPath}RobotoMono-Regular.ttf')
  }

  @font-face {
    font-family: 'Roboto Mono';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}RobotoMono-Italic.ttf')
  }

  @font-face {
    font-family: 'Roboto Mono';
    font-weight: 500;
    src: url('${publicFontPath}RobotoMono-Medium.ttf')
  }

  @font-face {
    font-family: 'Roboto Mono';
    font-weight: 500;
    font-style: italic;
    src: url('${publicFontPath}RobotoMono-MediumItalic.ttf')
  }

  @font-face {
    font-family: 'Roboto Mono';
    font-weight: 700;
    src: url('${publicFontPath}RobotoMono-Bold.ttf');
  }

  @font-face {
    font-family: 'Roboto Mono';
    font-weight: 700;
    font-style: italic;
    src: url('${publicFontPath}RobotoMono-BoldItalic.ttf');
  }

  // Roboto Slab

  @font-face {
    font-family: 'Roboto Slab';
    font-weight: 100;
    src: url('${publicFontPath}RobotoSlab-Thin.ttf');
  }

  @font-face {
    font-family: 'Roboto Slab';
    font-weight: 300;
    src: url('${publicFontPath}RobotoSlab-Light.ttf')
  }

  @font-face {
    font-family: 'Roboto Slab';
    font-weight: 400;
    src: url('${publicFontPath}RobotoSlab-Regular.ttf')
  }

  @font-face {
    font-family: 'Roboto Slab';
    font-weight: 700;
    src: url('${publicFontPath}RobotoSlab-Bold.ttf');
  }

  // Rollo
  @font-face {
    font-family: 'Rollo';
    src: url('${publicFontPath}Rollo.eot');
    src: url('${publicFontPath}Rollo.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Rollo.woff2') format('woff2'),
      url('${publicFontPath}Rollo.woff') format('woff'),
      url('${publicFontPath}Rollo.otf') format('opentype'),
      url('${publicFontPath}Rollo.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  // Romantic Couple
  @font-face {
    font-family: 'Romantic Couple';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}Romantic-Couple.eot');
    src: url('${publicFontPath}Romantic-Couple.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Romantic-Couple.woff') format('woff'),
      url('${publicFontPath}Romantic-Couple.woff2') format('woff2'),
      url('${publicFontPath}Romantic-Couple.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Romantic Couple Script';
    font-weight: 400;
    font-style: normal;
    src: url('${publicFontPath}Romantic-Couple-Script.eot');
    src: url('${publicFontPath}Romantic-Couple-Script.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Romantic-Couple-Script.woff') format('woff'),
      url('${publicFontPath}Romantic-Couple-Script.woff2') format('woff2'),
      url('${publicFontPath}Romantic-Couple-Script.ttf') format('truetype');
  }

  // Rouge Script
  @font-face {
    font-family: 'Rouge Script';
    font-weight: 400;
    src: url('${publicFontPath}RougeScript-Regular.ttf');
  }

  // Rozha One
  @font-face {
    font-family: 'Rozha One';
    font-weight: 400;
    src: url('${publicFontPath}RozhaOne-Regular.ttf');
  }

  // Rushfor
  @font-face {
    font-family: 'Rushfor';
    src: url('${publicFontPath}Rushfor.eot');
    src: url('${publicFontPath}Rushfor.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Rushfor.woff2') format('woff2'),
      url('${publicFontPath}Rushfor.woff') format('woff'),
      url('${publicFontPath}Rushfor.otf') format('opentype'),
      url('${publicFontPath}Rushfor.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }


  // Rye
  @font-face {
    font-family: 'Rye';
    font-weight: 400;
    src: url('${publicFontPath}Rye-Regular.ttf');
  }

  // Salma Alianda Script
  @font-face {
    font-family: 'Salma Alianda Script';
    font-weight: 400;
    src: url('${publicFontPath}SalmaAliandaScript-Regular.woff') format('woff'),
    url('${publicFontPath}SalmaAliandaScript-Regular.eot') format('eot'),
    url('${publicFontPath}SalmaAliandaScript-Regular.ttf') format('truetype');
  }

  // Sancreek
  @font-face {
    font-family: 'Sancreek';
    font-weight: 400;
    src: url('${publicFontPath}Sancreek-Regular.ttf')
  }

  // Santana
  @font-face {
    font-family: 'Santana';
    src: url('${publicFontPath}Santana.eot');
    src: url('${publicFontPath}Santana.eot?#iefix') format('embedded-opentype'),
        url('${publicFontPath}Santana.otf') format("opentype"),
        url('${publicFontPath}Santana.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  // Savannah
  @font-face {
    font-family: 'Savannah';
    src: url('${publicFontPath}savannah-regular.woff2') format('woff2'),
        url('${publicFontPath}savannah-regular.woff') format('woff'),
        url('${publicFontPath}savannah-regular.otf') format('opentype'),
        url('${publicFontPath}savannah-regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  // Savannah Alt
  @font-face {
    font-family: 'Savannah Alt';
    src: url('${publicFontPath}Savannah-Alt-Regular.woff2') format('woff2'),
        url('${publicFontPath}Savannah-Alt-Regular.woff') format('woff'),
        url('${publicFontPath}Savannah-Alt-Regular.otf') format('opentype'),
        url('${publicFontPath}Savannah-Alt-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  // Serendipity
  @font-face {
    font-family: 'Serendipity';
    font-weight: 400;
    src: url('${publicFontPath}Serendipity.eot');
    src: url('${publicFontPath}Serendipity.eot?#iefix') format('embedded-opentype'),
    url('${publicFontPath}Serendipity.ttf') format('truetype');

  }

  // Serendipity alternates
  @font-face {
    font-family: 'Serendipity One';
    font-weight: 400;
    src: url('${publicFontPath}SerendipityOne-Regular.ttf');
  }

  @font-face {
    font-family: 'Serendipity Wide One';
    font-weight: 400;
    src: url('${publicFontPath}SerendipityOneWide-Regular.ttf');
  }

  @font-face {
    font-family: 'Serendipity Two';
    font-weight: 400;
    src: url('${publicFontPath}SerendipityTwo-Regular.ttf');
  }

  @font-face {
    font-family: 'Serendipity Wide Two';
    font-weight: 400;
    src: url('${publicFontPath}SerendipityTwoWide-Regular.ttf');
  }

  @font-face {
    font-family: 'Serendipity Three';
    font-weight: 400;
    src: url('${publicFontPath}SerendipityThree-Regular.ttf');
  }

  @font-face {
    font-family: 'Serendipity Wide Three';
    font-weight: 400;
    src: url('${publicFontPath}SerendipityThreeWide-Regular.ttf');
  }

  @font-face {
    font-family: 'Serendipity Ligatures';
    font-weight: 400;
    src: url('${publicFontPath}SerendipityLigatures-Regular.ttf');
  }

  @font-face {
    font-family: 'Serendipity Wide Ligatures';
    font-weight: 400;
    src: url('${publicFontPath}SerendipityLigaturesWide-Regular.ttf');
  }

  // Serendipity Wide
  @font-face {
    font-family: 'Serendipity Wide';
    font-weight: 400;
    src: url('${publicFontPath}Serendipity_Wide.woff') format('woff'),
        url('${publicFontPath}Serendipity-Wide.ttf');
  }

  // Serenity
  @font-face {
    font-family: 'Serenity';
    font-weight: 400;
    src: url('${publicFontPath}Serenity.ttf');
  }

  @font-face {
    font-family: 'Serenity';
    font-weight: 700;
    src: url('${publicFontPath}SerenityBold.ttf');
  }

  // Slight
  @font-face {
    font-family: 'Slight';
    font-weight: 400;
    src: url('${publicFontPath}Slight-Regular.otf');
  }

  // Smokum
  @font-face {
    font-family: 'Smokum';
    font-weight: 400;
    src: url('${publicFontPath}Smokum-Regular.ttf');
  }

  // Sorts Mill Goudy
  @font-face {
    font-family: 'Sorts Mill Goudy';
    font-weight: 400;
    src: url('${publicFontPath}SortsMillGoudy-Regular.ttf');
  }

  @font-face {
    font-family: 'Sorts Mill Goudy';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}SortsMillGoudy-Italic.ttf');
  }

  // Source Sans Pro
  @font-face {
    font-family: 'Source Sans Pro';
    src: url('${publicFontPath}SourceSansPro-ExtraLight.woff2') format('woff2'),
        url('${publicFontPath}SourceSansPro-ExtraLight.woff') format('woff'),
        url('${publicFontPath}SourceSansPro-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'Source Sans Pro';
    src: url('${publicFontPath}SourceSansPro-ExtraLightItalic.woff2') format('woff2'),
        url('${publicFontPath}SourceSansPro-ExtraLightItalic.woff') format('woff'),
        url('${publicFontPath}SourceSansPro-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
  }

  @font-face {
    font-family: 'Source Sans Pro';
    src: url('${publicFontPath}SourceSansPro-Light.woff2') format('woff2'),
        url('${publicFontPath}SourceSansPro-Light.woff') format('woff'),
        url('${publicFontPath}SourceSansPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Source Sans Pro';
    src: url('${publicFontPath}SourceSansPro-LightItalic.woff2') format('woff2'),
        url('${publicFontPath}SourceSansPro-LightItalic.woff') format('woff'),
        url('${publicFontPath}SourceSansPro-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'Source Sans Pro';
    src: url('${publicFontPath}SourceSansPro-Regular.woff2') format('woff2'),
        url('${publicFontPath}SourceSansPro-Regular.woff') format('woff'),
        url('${publicFontPath}SourceSansPro-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Source Sans Pro';
    src: url('${publicFontPath}SourceSansPro-RegularItalic.woff2') format('woff2'),
        url('${publicFontPath}SourceSansPro-RegularItalic.woff') format('woff'),
        url('${publicFontPath}SourceSansPro-RegularItalic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: 'Source Sans Pro';
    src: url('${publicFontPath}SourceSansPro-SemiBold.woff2') format('woff2'),
        url('${publicFontPath}SourceSansPro-SemiBold.woff') format('woff'),
        url('${publicFontPath}SourceSansPro-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Source Sans Pro';
    src: url('${publicFontPath}SourceSansPro-SemiBoldItalic.woff2') format('woff2'),
        url('${publicFontPath}SourceSansPro-SemiBoldItalic.woff') format('woff'),
        url('${publicFontPath}SourceSansPro-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: 'Source Sans Pro';
    src: url('${publicFontPath}SourceSansPro-Bold.woff2') format('woff2'),
        url('${publicFontPath}SourceSansPro-Bold.woff') format('woff'),
        url('${publicFontPath}SourceSansPro-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Source Sans Pro';
    src: url('${publicFontPath}SourceSansPro-BoldItalic.woff2') format('woff2'),
        url('${publicFontPath}SourceSansPro-BoldItalic.woff') format('woff'),
        url('${publicFontPath}SourceSansPro-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: 'Source Sans Pro';
    src: url('${publicFontPath}SourceSansPro-Black.woff2') format('woff2'),
        url('${publicFontPath}SourceSansPro-Black.woff') format('woff'),
        url('${publicFontPath}SourceSansPro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Source Sans Pro';
    src: url('${publicFontPath}SourceSansPro-BlackItalic.woff2') format('woff2'),
        url('${publicFontPath}SourceSansPro-BlackItalic.woff') format('woff'),
        url('${publicFontPath}SourceSansPro-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
  }

  // Stint Ultra Condensed
  @font-face {
    font-family: 'Stint Ultra Condensed';
    font-weight: 400;
    src: url('${publicFontPath}StintUltraCondensed-Regular.ttf')
  }

  // Style Clubs
  @font-face {
    font-family: 'Style Clubs';
    src: url('${publicFontPath}Style-Clubs-Serif.eot');
    src: url('${publicFontPath}Style-Clubs-Serif.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Style-Clubs-Serif.woff2') format('woff2'),
      url('${publicFontPath}Style-Clubs-Serif.woff') format('woff'),
      url('${publicFontPath}Style-Clubs-Serif.otf') format('opentype'),
      url('${publicFontPath}Style-Clubs-Serif.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Style Clubs';
    src: url('${publicFontPath}Style-Clubs-Serif-Italic.eot');
    src: url('${publicFontPath}Style-Clubs-Serif-Italic.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Style-Clubs-Serif-Italic.woff2') format('woff2'),
      url('${publicFontPath}Style-Clubs-Serif-Italic.woff') format('woff'),
      url('${publicFontPath}Style-Clubs-Serif-Italic.svg') format('svg'),
      url('${publicFontPath}Style-Clubs-Serif-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
  }

  // Stylish Calligraphy
  @font-face {
    font-family: 'Stylish Calligraphy';
    font-weight: 400;
    src: url('${publicFontPath}StylishCalligraphy.ttf');
  }

  // Tangerine

  @font-face {
    font-family: 'Tangerine';
    font-weight: 400;
    src: url('${publicFontPath}Tangerine-Regular.ttf')
  }

  @font-face {
    font-family: 'Tangerine';
    font-weight: 700;
    src: url('${publicFontPath}Tangerine-Bold.ttf');
  }

  // Thirsk Carved
  @font-face {
    font-family: 'Thirsk Carved';
    src: url('${publicFontPath}Thirsk-Carved-Font-by-TTF.eot');
    src: url('${publicFontPath}Thirsk-Carved-Font-by-TTF.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Thirsk-Carved-Font-by-TTF.woff2') format('woff2'),
      url('${publicFontPath}Thirsk-Carved-Font-by-TTF.woff') format('woff'),
      url('${publicFontPath}Thirsk-Carved-Font-by-TTF.otf') format('opentype'),
      url('${publicFontPath}Thirsk-Carved-Font-by-TTF.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Thirsk Carved';
    src: url('${publicFontPath}Thirsk-Carved-Italic-by-TTF.eot');
    src: url('${publicFontPath}Thirsk-Carved-Italic-by-TTF.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Thirsk-Carved-Italic-by-TTF.woff2') format('woff2'),
      url('${publicFontPath}Thirsk-Carved-Italic-by-TTF.woff') format('woff'),
      url('${publicFontPath}Thirsk-Carved-Italic-by-TTF.otf') format('opentype'),
      url('${publicFontPath}Thirsk-Carved-Italic-by-TTF.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
  }

  // Trend Sans One Regular
  @font-face {
    font-family: 'Trend Sans One';
    font-weight: 400;
    src: url('${publicFontPath}TrendSans-One-Regular.otf');
  }

  // Trend Sans Five Regular
  @font-face {
    font-family: 'Trend Sans Five';
    font-weight: 400;
    src: url('${publicFontPath}TrendSans-Five-Regular.otf');
  }


  // Ultra
  @font-face {
    font-family: 'Ultra';
    font-weight: 400;
    src: url('${publicFontPath}Ultra-Regular.ttf');
  }

  // Unna
  @font-face {
    font-family: 'Unna';
    font-weight: 400;
    src: url('${publicFontPath}Unna-Regular.ttf');
  }

  @font-face {
    font-family: 'Unna';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}Unna-Italic.ttf');
  }

  @font-face {
    font-family: 'Unna';
    font-weight: 700;
    src: url('${publicFontPath}Unna-Bold.ttf');
  }

  @font-face {
    font-family: 'Unna';
    font-weight: 700;
    font-style: italic;
    src: url('${publicFontPath}Unna-BoldItalic.ttf');
  }

  // vLetter Dakota
  @font-face {
    font-family: 'vLetter Dakota2';
    font-weight: 400;
    src: url('${publicFontPath}Unna-Bold.ttf');
  }

  // Voces

  @font-face {
    font-family: 'Voces';
    font-weight: 400;
    src: url('${publicFontPath}Voces-Regular.ttf')
  }

  // Wallace
  @font-face {
    font-family: 'Wallace';
    src: url('${publicFontPath}Wallace.eot');
    src: url('${publicFontPath}Wallace.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Wallace.woff2') format('woff2'),
      url('${publicFontPath}Wallace.woff') format('woff'),
      url('${publicFontPath}Wallace.otf') format('opentype'),
      url('${publicFontPath}Wallace.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  // Weisston
  @font-face {
    font-family: 'Weisston';
    src: url('${publicFontPath}Weisston-Script.eot');
    src: url('${publicFontPath}Weisston-Script.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Weisston-Script.woff2') format('woff2'),
      url('${publicFontPath}Weisston-Script.woff') format('woff'),
      url('${publicFontPath}Weisston-Script.otf') format('opentype'),
      url('${publicFontPath}Weisston-Script.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  // Wondar Quason
  @font-face {
    font-family: 'Wondar Quason';
    src: url('${publicFontPath}Wondar-Quason.eot');
    src: url('${publicFontPath}Wondar-Quason.eot?#iefix') format('embedded-opentype'),
      url('${publicFontPath}Wondar-Quason.woff2') format('woff2'),
      url('${publicFontPath}Wondar-Quason.woff') format('woff'),
      url('${publicFontPath}Wondar-Quason.otf') format('opentype'),
      url('${publicFontPath}Wondar-Quason.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  // WorkSans
  @font-face {
    font-family: 'Work Sans';
    font-weight: 100;
    src: url('${publicFontPath}WorkSans-Thin.ttf');
  }

  @font-face {
    font-family: 'Work Sans';
    font-weight: 200;
    src: url('${publicFontPath}WorkSans-ExtraLight.ttf');
  }

  @font-face {
    font-family: 'Work Sans';
    font-weight: 300;
    src: url('${publicFontPath}WorkSans-Light.ttf');
  }

  @font-face {
    font-family: 'Work Sans';
    font-weight: 400;
    src: url('${publicFontPath}WorkSans-Regular.ttf');
  }

  @font-face {
    font-family: 'Work Sans';
    font-weight: 500;
    src: url('${publicFontPath}WorkSans-Medium.ttf');
  }

  @font-face {
    font-family: 'Work Sans';
    font-weight: 600;
    src: url('${publicFontPath}WorkSans-SemiBold.ttf');
  }

  @font-face {
    font-family: 'Work Sans';
    font-weight: 700;
    src: url('${publicFontPath}WorkSans-Bold.ttf');
  }

  @font-face {
    font-family: 'Work Sans';
    font-weight: 800;
    src: url('${publicFontPath}WorkSans-ExtraBold.ttf');
  }

  @font-face {
    font-family: 'Work Sans';
    font-weight: 900;
    src: url('${publicFontPath}WorkSans-Black.ttf');
  }

  // Yellowtail
  @font-face {
    font-family: 'Yellowtail';
    font-weight: 400;
    src: url('${publicFontPath}Yellowtail-Regular.ttf')
  }

  // Zilla Slab
  @font-face {
    font-family: 'Zilla Slab';
    font-weight: 300;
    src: url('${publicFontPath}ZillaSlab-Light.eot');
    src: url('${publicFontPath}ZillaSlab-Light.eot?#iefix') format('embedded-opentype'),
    url('${publicFontPath}ZillaSlab-Light.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Zilla Slab';
    font-weight: 300;
    font-style: italic;
    src: url('${publicFontPath}ZillaSlab-LightItalic.eot');
    src: url('${publicFontPath}ZillaSlab-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('${publicFontPath}ZillaSlab-LightItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Zilla Slab';
    font-weight: 400;
    src: url('${publicFontPath}ZillaSlab-Regular.eot');
    src: url('${publicFontPath}ZillaSlab-Regular.eot?#iefix') format('embedded-opentype'),
    url('${publicFontPath}ZillaSlab-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Zilla Slab';
    font-weight: 400;
    font-style: italic;
    src: url('${publicFontPath}ZillaSlab-Italic.eot');
    src: url('${publicFontPath}ZillaSlab-Italic.eot?#iefix') format('embedded-opentype'),
    url('${publicFontPath}ZillaSlab-Italic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Zilla Slab';
    font-weight: 500;
    src: url('${publicFontPath}ZillaSlab-Medium.eot');
    src: url('${publicFontPath}ZillaSlab-Medium.eot?#iefix') format('embedded-opentype'),
    url('${publicFontPath}ZillaSlab-Medium.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Zilla Slab';
    font-weight: 500;
    font-style: italic;
    src: url('${publicFontPath}ZillaSlab-MediumItalic.eot');
    src: url('${publicFontPath}ZillaSlab-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('${publicFontPath}ZillaSlab-MediumItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Zilla Slab';
    font-weight: 600;
    src: url('${publicFontPath}ZillaSlab-SemiBold.eot');
    src: url('${publicFontPath}ZillaSlab-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('${publicFontPath}ZillaSlab-SemiBold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Zilla Slab';
    font-weight: 600;
    font-style: italic;
    src: url('${publicFontPath}ZillaSlab-SemiBoldItalic.eot');
    src: url('${publicFontPath}ZillaSlab-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('${publicFontPath}ZillaSlab-SemiBoldItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Zilla Slab';
    font-weight: 700;
    src: url('${publicFontPath}ZillaSlab-Bold.eot');
    src: url('${publicFontPath}ZillaSlab-Bold.eot?#iefix') format('embedded-opentype'),
    url('${publicFontPath}ZillaSlab-Bold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Zilla Slab';
    font-weight: 700;
    font-style: italic;
    src: url('${publicFontPath}ZillaSlab-BoldItalic.eot');
    src: url('${publicFontPath}ZillaSlab-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('${publicFontPath}ZillaSlab-BoldItalic.ttf') format('truetype');
  }

  // Zaner Four
  @font-face {
    font-family: 'Zaner Four';
    font-weight: 400;
    src: url('${publicFontPath}P22Zaner4-Regular.ttf');
  }

  // Zoxi
  @font-face {
    font-family: 'Zoxi';
    font-weight: 400;
    src: url('${publicFontPath}Zoxi-Regular.otf');
  }
`;

export default fonts;
