import React from 'react';
import Head from 'next/head';

import { Grid } from '@zola/zola-ui/src/components/Grid';
import H from '@zola/zola-ui/src/typography/Headings';
import * as BREAKPOINTS from '@zola/zola-ui/src/styles/emotion/mediaQueryV2';
import { SecondaryLink } from 'components/baby/zola-ui/LinkV2';
import BabyFooter from '@zola/zola-ui/src/components/footers/baby/BabyFooter';
import getSourceElements, { ImageType } from 'lib/client/Image/getSourceElements';
import UnifiedNav from 'components/common/header/UnifiedNav';

import Mobile from 'assets/images/baby/404/mobile.jpg';
import Mobile2x from 'assets/images/baby/404/mobile@2x.jpg';
import Tablet from 'assets/images/baby/404/tablet.jpg';
import Tablet2x from 'assets/images/baby/404/tablet@2x.jpg';
import Desktop from 'assets/images/baby/404/desktop.jpg';
import Desktop2x from 'assets/images/baby/404/desktop@2x.jpg';

import { StyledPage, GridTile, ContentTile } from 'components/baby/404/CustomErrorPage.styles';

const HeroImages: ImageType = {
  // webp: [
  //   { url: MobileWebp, maxBreakpoint: (BREAKPOINTS.SCREEN_SM - 1).toString(), url2x: Mobile2xWebp },
  //   { url: TabletWebp, maxBreakpoint: (BREAKPOINTS.SCREEN_MD - 1).toString(), url2x: Tablet2xWebp },
  //   { url: DesktopWebp, minBreakpoint: BREAKPOINTS.SCREEN_MD.toString(), url2x: Desktop2xWebp },
  // ],
  jpg: [
    { url: Mobile, maxBreakpoint: (BREAKPOINTS.SCREEN_SM - 1).toString(), url2x: Mobile2x },
    { url: Tablet, maxBreakpoint: (BREAKPOINTS.SCREEN_MD - 1).toString(), url2x: Tablet2x },
    { url: Desktop, minBreakpoint: BREAKPOINTS.SCREEN_MD.toString(), url2x: Desktop2x },
  ],
};

type CustomErrorProps = {
  statusCode: number;
};

const CustomErrorPage: React.FC<CustomErrorProps> = ({ statusCode = 404 }) => {
  return (
    <>
      <Head>
        <title>{statusCode === 404 ? 'Page Not Found' : 'Error Occurred'} - Zola Baby</title>
      </Head>
      <UnifiedNav />
      <StyledPage>
        <Grid>
          <GridTile>
            <Grid
              mediaQueries={{
                mobile: {
                  columns: '1fr',
                  bordered: false,
                },
                tablet: {
                  columns: '1fr',
                  bordered: false,
                },
                desktop: {
                  columns: 'repeat(2, minmax(0, 1fr))',
                  bordered: false,
                },
              }}
            >
              <ContentTile>
                <H.TitleMedium1 presentation="h3">This page is a little fussy</H.TitleMedium1>
                <H.Title2 presentation="h5">
                  We can’t seem to find the registry you’re looking for. Try searching for the
                  registry by the registrant’s name.
                </H.Title2>
                <SecondaryLink href="/search/baby-registry" arrow>
                  Search for a baby registry
                </SecondaryLink>
              </ContentTile>
              <GridTile>
                <picture>
                  {/* {getSourceElements(HeroImages.webp, 'webp', true)} */}
                  {getSourceElements(HeroImages.jpg, 'jpg', true)}
                  <img src={Desktop} alt="" />
                </picture>
              </GridTile>
            </Grid>
          </GridTile>
          <BabyFooter />
        </Grid>
      </StyledPage>
    </>
  );
};

export default CustomErrorPage;
