const queryNoShow = '@media (max-width: 0px)';
const queryAlwaysShow = '@media (min-width: 1px)';

export const MEDIA_QUERY_FIXED_MOBILE = {
  MOBILE_XS: queryAlwaysShow,
  MOBILE: queryAlwaysShow, // always show
  TABLET: queryNoShow,
  DESKTOP: queryNoShow,
  DESKTOP_LG: queryNoShow,
  DESKTOP_XL: queryNoShow,
  DESKTOP_MASSIVE: queryNoShow,
  EXCLUDE_MOBILE_XS: queryNoShow,
  EXCLUDE_MOBILE: queryNoShow,
  EXCLUDE_TABLET: queryAlwaysShow, // show
  EXCLUDE_DESKTOP: queryAlwaysShow, // show
  EXCLUDE_DESKTOP_LG: queryAlwaysShow, // show
  EXCLUDE_DESKTOP_XL: queryAlwaysShow, // show
  NOT_MOBILE: queryNoShow,
};

export const MEDIA_QUERY_FIXED_DESKTOP = {
  MOBILE_XS: queryNoShow,
  MOBILE: queryNoShow,
  TABLET: queryNoShow,
  DESKTOP: queryAlwaysShow,
  DESKTOP_LG: queryAlwaysShow,
  DESKTOP_XL: queryAlwaysShow,
  DESKTOP_MASSIVE: queryAlwaysShow,
  EXCLUDE_MOBILE_XS: queryAlwaysShow,
  EXCLUDE_MOBILE: queryAlwaysShow,
  EXCLUDE_TABLET: queryAlwaysShow,
  EXCLUDE_DESKTOP: queryNoShow,
  EXCLUDE_DESKTOP_LG: queryNoShow,
  EXCLUDE_DESKTOP_XL: queryNoShow,
  NOT_MOBILE: queryAlwaysShow,
};
