import COLORS, { COLORS_VARIANTS } from '@zola/zola-ui/src/styles/emotion/colors';

const EERIE_BLACK = '#191919';
export const LIGHT_ICON_BG = COLORS.CLOUD;
export const LIGHT_ICON_TEXT = EERIE_BLACK;
export const LIGHT_CART_COUNT_TEXT = COLORS.CLOUD;
export const LIGHT_CART_COUNT_BG = COLORS.RED_MESSAGING;
export const DARK_ICON_BG = 'rgba(255, 255, 255, 0.2)';
export const DARK_ICON_TEXT = COLORS.CLOUD;
export const DARK_ICON_COUNT_TEXT = COLORS.RED_MESSAGING;
export const DARK_ICON_COUNT_BG = COLORS_VARIANTS.MELON_24;
export const DARK_ERROR = '#E8B2C2';
