import React, { useContext, useRef } from 'react';
import type { WThemeGroupComponentBorderView } from '@zola/svc-web-api-ts-client';
import { renderAbsoluteAssets } from 'components/publicWebsiteV2/util/renderHelpers';
import { DeviceContext } from 'contexts/DeviceContext';
import { useContainerDimensions } from '@zola/zola-ui/src/hooks/useContainerDimensions';
import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';
import { mapBackgroundToInlineStyle } from 'components/publicWebsiteV2/util/mappers';
import {
  StandardContainer,
  ScoopedContainer,
  OuterDoubleBorder,
  InnerDoubleBorder,
  getBasicBorderStyles,
} from './InnerContainer.styles';

/**
 * Handles borders and inner background color, images
 * Used on single and multi page layouts
 */
const InnerContainer: React.FC<{
  // Even spacing along X axis; for names module on single page
  evenSpacing?: boolean;
  // Use single page-specific
  isSinglePage?: boolean;
  className?: string;
  disableAssets?: boolean;
}> = ({ evenSpacing, disableAssets, isSinglePage, children }) => {
  const {
    state: {
      wedding,
      components: { globalAccentColor },
      inPreview,
    },
  } = useWebsiteThemeContext();
  const { device } = useContext(DeviceContext);
  const { background, absolute_assets, border } =
    wedding?.public_theme_v2?.components?.FIXED_WIDTH_CONTAINER || {};

  const { background: containerBackground } = wedding?.public_theme_v2?.components?.GLOBAL || {};

  const containerRef = useRef(null);
  const { width: containerWidth } = useContainerDimensions(containerRef);

  const commonContainerProps = {
    evenSpacing,
    ref: containerRef,
    isSinglePage,
    isMobilePreview: inPreview === 'MOBILE',
    isDesktopPreview: inPreview === 'DESKTOP',
    hasBackgroundColor: Boolean(background?.background_color?.value),
  };
  if (
    !border ||
    (([
      'NONE',
      'BLOCK',
      'ROUNDED',
    ] as unknown) as WThemeGroupComponentBorderView.ShapeEnum[]).includes(border?.shape)
  ) {
    return (
      <StandardContainer
        {...commonContainerProps}
        borderWidth={border?.border_width || 0}
        style={{
          ...mapBackgroundToInlineStyle(background),
          ...getBasicBorderStyles(border, globalAccentColor),
        }}
      >
        {children}
        {!disableAssets && renderAbsoluteAssets({ a: absolute_assets, containerWidth, device })}
      </StandardContainer>
    );
  }

  if (
    (([
      'BLOCK_DOUBLE',
      'BLOCK_DOUBLE_WIDE',
    ] as unknown) as WThemeGroupComponentBorderView.ShapeEnum[]).includes(border?.shape)
  ) {
    return (
      <OuterDoubleBorder
        {...commonContainerProps}
        isBlockDoubleWide={
          border?.shape ===
          (('BLOCK_DOUBLE_WIDE' as unknown) as WThemeGroupComponentBorderView.ShapeEnum)
        }
        style={{
          ...mapBackgroundToInlineStyle(background),
          ...getBasicBorderStyles(border, globalAccentColor),
        }}
      >
        <InnerDoubleBorder style={getBasicBorderStyles(border, globalAccentColor)}>
          {!disableAssets && renderAbsoluteAssets({ a: absolute_assets, containerWidth, device })}
          {children}
        </InnerDoubleBorder>
      </OuterDoubleBorder>
    );
  }
  // TODO: FIGURE OUT HOW TO INLINE MEDIA QUERY STYLES
  return (
    <ScoopedContainer
      circleBgColor={
        containerBackground?.background_image?.image_url
          ? 'transparent'
          : `#${containerBackground?.background_color?.value}`
      }
      borderColor={globalAccentColor}
      borderWidth={border?.border_width}
      {...commonContainerProps}
      style={mapBackgroundToInlineStyle(background)}
    >
      {!disableAssets && renderAbsoluteAssets({ a: absolute_assets, containerWidth, device })}
      {children}
    </ScoopedContainer>
  );
};

export default InnerContainer;
