/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import { css } from '@emotion/react';
import type { CSSProperties } from 'react';
import { formatFontFamilyName } from 'cards/util/formatFontFamilyName';
import type { FullComponentFontView, MediaQueryType, PartialComponentFontView } from './types';
import { mapWeightNameToNumericalValue, mapTextTransformValue, fillFontProperties } from './index';

type ComponentTypePropType = React.FC<any> | 'h1' | 'h2' | 'h3' | 'div' | 'p' | 'span';
export type MapFontValuesProps = {
  fallback: FullComponentFontView;
  partial?: PartialComponentFontView;
  responsiveFontSize?: boolean;
  mediaQuery?: MediaQueryType;
  excludeFontSize?: boolean;
  ComponentType?: ComponentTypePropType;
};

const mapFontValuesToStyleObject = (f: FullComponentFontView) => ({
  fontFamily: formatFontFamilyName(f.fontFamily),
  color: `#${f.color}`,
  fontWeight: mapWeightNameToNumericalValue(f.fontWeight),
  textTransform: f.isCursive ? 'none' : mapTextTransformValue(f.textTransform),
  lineHeight: f.lineHeight,
  letterSpacing: f.letterSpacing > 0 ? `${f.letterSpacing}px` : 'normal',
});

const mapFontValuesToComponent = ({
  fallback,
  partial,
  responsiveFontSize,
  mediaQuery,
  excludeFontSize,
  ComponentType = 'h2',
}: MapFontValuesProps) => {
  const filledFont = fillFontProperties(fallback, partial);

  const HeadingComponent = ({ style, children, ...rest }: any): React.ReactElement => {
    const styleObject: CSSProperties = {
      ...style,
      ...mapFontValuesToStyleObject(filledFont),
    };
    // Don't include font size if we are adding responsive font size
    // b/c the inline style will override the emotion css font size
    if (!excludeFontSize && !responsiveFontSize) {
      styleObject.fontSize = `${filledFont.fontSize}px`;
    }
    return (
      <ComponentType
        // TODO: CONVERT TO USING REACT MEDIA QUERY HOOK AND ADJUSTING INLINE STYLE
        css={
          !excludeFontSize &&
          responsiveFontSize &&
          mediaQuery &&
          css`
            font-size: ${filledFont.fontSize}px;
            ${mediaQuery.MOBILE} {
              font-size: ${Math.ceil(filledFont.fontSize * 0.8)}px;
            }
          `
        }
        style={styleObject}
        {...rest}
      >
        {children}
      </ComponentType>
    );
  };

  return HeadingComponent;
};

export type FontValueComponentFactoryReturnType = (
  ComponentTypeProp: ComponentTypePropType
) => ({ style, children, ...rest }: any) => React.ReactElement;

export const mapFontValuesToComponentFactory = ({
  fallback,
  partial,
  responsiveFontSize,
  mediaQuery,
  excludeFontSize,
}: Omit<MapFontValuesProps, 'ComponentType'>): FontValueComponentFactoryReturnType => {
  const filledFont = fillFontProperties(fallback, partial);

  const styleComponentFct = (ComponentTypeProp: ComponentTypePropType) => {
    const HeadingComponent = ({ style, children, ...rest }: any): React.ReactElement => {
      const styleObject: CSSProperties = {
        ...style,
        ...mapFontValuesToStyleObject(filledFont),
      };

      // Don't include font size if we are adding responsive font size
      // b/c the inline style will override the emotion css font size
      if (!excludeFontSize && !responsiveFontSize) {
        styleObject.fontSize = `${filledFont.fontSize}px`;
      }
      return (
        <ComponentTypeProp
          // TODO: CONVERT TO USING REACT MEDIA QUERY HOOK AND ADJUSTING INLINE STYLE
          css={
            !excludeFontSize &&
            responsiveFontSize &&
            mediaQuery &&
            css`
              font-size: ${filledFont.fontSize}px;
              ${mediaQuery.MOBILE} {
                font-size: ${Math.ceil(filledFont.fontSize * 0.8)}px;
              }
            `
          }
          style={styleObject}
          {...rest}
        >
          {children}
        </ComponentTypeProp>
      );
    };
    return HeadingComponent;
  };
  return styleComponentFct;
};

export default mapFontValuesToComponent;
