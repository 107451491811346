import { useAnimationContext } from 'components/manage/EditWebsite/EditWebsiteAddEffects/components/AnimationContext/AnimationContext';
import React, { Fragment } from 'react';
import { PageAnimation } from '@zola/zola-ui/src/animations/PageAnimations/PageAnimation/PageAnimation';
import {
  AnimationProps,
  PageAnimationTypes,
} from '@zola/zola-ui/src/animations/PageAnimations/types';

export type PageAnimationWrapperProps = {
  children: React.ReactNode;
  animationProps?: AnimationProps;
  style?: React.CSSProperties;
  /** Overrides animation settings to hide the animation, used when we don't want to animate an element on every page, i.e. the nav */
  hideAnimation?: boolean;
  // override the selected animation
  animationNameOverride?: PageAnimationTypes;
  index?: number;
  showOnPageLoad?: boolean;
};

export const PageAnimationWrapper = (props: PageAnimationWrapperProps) => {
  const {
    children,
    animationProps,
    style,
    hideAnimation,
    index,
    animationNameOverride,
    showOnPageLoad,
  } = props;

  const {
    pageAnimation,
    showAnimationsInEditWebsite,
    speed,
    animationsEnabledForAccount,
    showDebug,
    startScaleAt,
    pageAnimationId,
    showAnimationPreview,
    translateYPercent,
    translateYPercentHero,
    translateYPercentNav,
    easingFunction,
    translateXPercent,
    speedHero,
    speedNav,
    startScaleAtHero,
    startScaleAtNav,
    easingFunctionHero,
    easingFunctionNav,
    translateXPercentHero,
    translateXPercentNav,
  } = useAnimationContext();

  const debugScale = startScaleAt ? startScaleAt / 100 : 0.8;

  const { isHero, isNav } = animationProps || {};

  const getDebugPropsToUse = () => {
    if (isHero) {
      return {
        ...animationProps,
        speed: speedHero || speed,
        startScaleAt: startScaleAtHero ? startScaleAtHero / 100 : debugScale,
        translateYPercent,
        translateYPercentHero,
        translateYPercentNav,
        easeFunction: easingFunctionHero || easingFunction,
        translateXPercent: translateXPercentHero || translateXPercent,
      };
    }

    if (isNav) {
      return {
        ...animationProps,
        speed: speedNav || speed,
        startScaleAt: startScaleAtNav ? startScaleAtNav / 100 : debugScale,
        translateYPercent,
        translateYPercentHero,
        translateYPercentNav,
        easeFunction: easingFunctionNav || easingFunction,
        translateXPercent: translateXPercentNav || translateXPercent,
      };
    }

    return {
      ...animationProps,
      speed,
      startScaleAt: debugScale,
      translateYPercent,
      translateYPercentHero,
      translateYPercentNav,
      easeFunction: easingFunction,
      translateXPercent,
    };
  };
  const animationPropsToUse = showDebug ? getDebugPropsToUse() : animationProps;

  const hasAnimationSelected = pageAnimation || showAnimationPreview;

  const showAnimations = animationsEnabledForAccount || showAnimationsInEditWebsite;

  if (showAnimations && !hideAnimation && hasAnimationSelected) {
    return (
      <PageAnimation
        pageAnimation={animationNameOverride || pageAnimation || 'page-animation-rise'} // default for preview
        showAnimations={showAnimations}
        animationProps={animationPropsToUse}
        style={style}
        key={pageAnimationId}
        index={index}
        showOnPageLoad={showOnPageLoad}
      >
        {children}
      </PageAnimation>
    );
  }

  return <Fragment>{children}</Fragment>;
};
