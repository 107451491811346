import styled from '@emotion/styled';
import type { WInFlowThemeComponentAssetSummaryView } from '@zola/svc-web-api-ts-client';
import { mapInFlowAssetToCSS } from 'components/publicWebsiteV2/util/mappers';
import type { DevicePartial } from 'components/publicWebsiteV2/util/getRelativeImgSize';

const MOBILE_NAV_HEIGHT = 84;

export const Container = styled.div<{
  addTopPadding?: boolean;
  containerWidth?: number;
  device?: DevicePartial;
  inFlowAssets?: WInFlowThemeComponentAssetSummaryView;
}>`
  position: relative;
  overflow: clip;
  // prevent collapsing margin
  padding-top: 1px;
  padding-bottom: 1px;

  ${({ inFlowAssets, device, containerWidth, theme }) =>
    mapInFlowAssetToCSS(inFlowAssets, device, containerWidth, theme.MEDIA_QUERY)}
  // add top padding so content does not overlap with mobile menu
  ${({ addTopPadding, theme }) =>
    addTopPadding
      ? `
      ${theme.MEDIA_QUERY.EXCLUDE_DESKTOP} {
        padding-top: ${MOBILE_NAV_HEIGHT}px;
      }`
      : ''}
`;
