import React from 'react';
import { useRouter } from 'next/router';
import DesktopHeader from './DesktopHeader';

/**
 * Handles conditionally showing the top content, including nav
 * - desktop or mobile
 * - not showing if only one page
 */
const Header = (): JSX.Element | null => {
  const router = useRouter();
  const { preview_type } = router?.query || {};

  if ((preview_type as string | undefined)?.toUpperCase() === 'SCREENSHOT') return null;

  return <DesktopHeader />;
};

export default Header;
